import React from "react";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Button from "../CustomButtons/Button";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import CardIcon from "../Card/CardIcon";
import {AddLocation} from "@material-ui/icons/";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Spinner from "../Spinner";
import {connect} from "react-redux";
import {
  assignLocation,
  fetchLocations,
} from "../../actions/vehiclesAction";

class LocationCard extends React.Component {
  constructor(props) {
    super(props);
    props.fetchLocations();
    const { selected } = props;
    //console.log("selected", selected);
    this.state = {
      // initialize the selected location with the one of the first selected vehicle
      location: selected && selected.length > 0 ? selected[0].location : ""
    }
  }

  handleSelect = event => {
    this.setState({ location: event.target.value});
  }

  assignLocation() {
    this.props.selected.map(vehicle => this.props.assignLocation(this.state.location, vehicle));
  }

  render() {
    const { classes, embedded, selected, locations} = this.props;
    if(locations === undefined)
      return <Spinner/>;

    const { location } = this.state;
    //console.log("Available locations", locations);
    //console.log("Selected location", location);
    const modified = selected.length === 1 ? location !== selected[0].location : true;

    return (
      <Card plain={embedded} className={classes.fullHeightCard}>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <AddLocation/>
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Assign Location</h4>
        </CardHeader>
        <CardBody>
          <p>Select the location to assgin to the selected vehicles.</p>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="location-select" className={classes.selectLabel}>
              Choose the location
            </InputLabel>
            <Select
              MenuProps={{className: classes.selectMenu}}
              classes={{select: classes.select}}
              value={location > 0 ? location : ""}
              onChange={this.handleSelect.bind(this)}
              inputProps={{name: "location", id: "location-select"}}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Choose the location
              </MenuItem>
              {locations && locations.map(c =>
                <MenuItem key={c.id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={c.id}
                >
                  {c.title} ({c.country})
                </MenuItem>
              )}
            </Select>
          </FormControl>

          <Button color="success"
                  disabled={selected.length === 0 || location === "" || !modified}
                  onClick={this.assignLocation.bind(this)}>
            Assign {selected.length > 0 && <strong>&nbsp;to {selected.length} Vehicles</strong>}
          </Button>

        </CardBody>
      </Card>
    );
  }
}

LocationCard.propTypes = {
  vehicle: PropTypes.object,
  classes: PropTypes.object.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  assignLocation: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired
}

function mapStateToProps(state) {
  return (
    {
      locations: state.data.locations
    });
}

export default connect(
  mapStateToProps,{
    fetchLocations,
    assignLocation
  })(LocationCard);

