import React from "react";
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import AddWizard from "../../components/Vehicles/AddWizard";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {fetchVehicle, beginAddVehicle} from "../../actions/vehiclesAction";
import CustomInput from "../../components/CustomInput/CustomInput";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

class AddVehicle extends React.Component {
  constructor(props) {
    super(props);
    props.beginAddVehicle();
  }

  render() {
    const { vehicle } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <AddWizard/>
          </GridItem>
          {vehicle &&
            <GridItem xs={12} md={4}>
              <GridContainer>
                <GridItem xs={12}>
                  <CustomInput
                    labelText={<span>Vehicle ID <small>(generated)</small></span>}
                    inputProps={{disabled: true, value: vehicle.id}}
                    formControlProps={{fullWidth: true}}
                  />
                </GridItem>
                <GridItem xs={9}>
                  <CustomInput
                    labelText={<span>Vehicle VIN</span>}
                    inputProps={{ disabled: true, value: vehicle.vin}}
                    formControlProps={{fullWidth: true}}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <CustomInput
                    labelText={<span>Version</span>}
                    inputProps={{ disabled: true, value: vehicle.version}}
                    formControlProps={{fullWidth: true}}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <CustomInput
                    labelText={<span>Brand</span>}
                    inputProps={{disabled: true, value: vehicle.brand.name}}
                    formControlProps={{fullWidth: true}}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <CustomInput
                    labelText={<span>Model</span>}
                    inputProps={{disabled: true, value: vehicle.model}}
                    formControlProps={{fullWidth: true}}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          }
        </GridContainer>
      </div>
    );
  }
}

AddVehicle.propTypes = {
  fetchVehicle: PropTypes.func.isRequired,
  beginAddVehicle: PropTypes.func.isRequired,
  vehicle: PropTypes.object
};

const mapStateToProps = state => ({
  vehicle: state.data.vehicle
});

export default connect(mapStateToProps, { fetchVehicle, beginAddVehicle })(withStyles(styles)(AddVehicle))
