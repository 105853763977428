import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle";

// core components
import GridContainer from "components/Grid/GridContainer.js";

import {Step} from "../../Wizard/Wizard";
import DriveTrain from "../DriveTrain";
import VehicleDetails from "../VehicleDetails";
import Environment from "../Environment";

class Step2 extends Step {
  constructor(props) {
    super(props);
    this.state = {
      environment: false,
      driveTrain: false,
      details: false
    };
  }

  sendState() {
    if(!this.isValidated())
      return this.state;
    const vehicle = {
      ...this.props.vehicle,
      environment: {
        ...this.state.environment,
        id: this.props.vehicle.id
      },
      driveTrain: {
        ...this.state.driveTrain,
        id: this.props.vehicle.id
      },
      milage: this.state.details.milage,
      weight: this.state.details.weight,
      modelYear: this.state.details.modelYear,
      bodyStyle: this.state.details.bodyStyle,
      condition: this.state.details.condition,
      used: this.state.details.used,
      version: this.props.vehicle.version // THIS IS IMPORTANT when using optimistic locking.
    }
    this.props.updateVehicle(vehicle);
    return this.state;
  }

  setDriveTrain = driveTrain => {
    this.setState({
      ...this.state,
      driveTrain: driveTrain
    })
  }

  setEnvironment = environment => {
    this.setState({
      ...this.state,
      environment: environment
    })
  }

  setDetails = details => {
    this.setState({
      ...this.state,
      details: details
    })
  }

  isValidated() {
    return true;
  }

  render() {
    const { classes } = this.props;
    return (
      <form>
        <GridContainer>
          <VehicleDetails embedded classes={classes} vehicle={this.props.vehicle} update={this.setDetails.bind(this)}/>
          <DriveTrain embedded classes={classes} vehicle={this.props.vehicle} update={this.setDriveTrain.bind(this)}/>
          <Environment embedded classes={classes} vehicle={this.props.vehicle} update={this.setEnvironment.bind(this)}/>
        </GridContainer>
      </form>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object,
  updateVehicle: PropTypes.func.isRequired,
  vehicle: PropTypes.object
};

export default withStyles(styles)(Step2);
