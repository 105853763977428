import { UserConstants } from '../actions/types';

const initialState = {
  loggedIn: false,
  loggingIn: false,
  user: undefined
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UserConstants.LOGIN_REQUEST:
      return {
        ...initialState,
        loggingIn: true,
        user: action.user
      };
    case UserConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false,
        user: action.user
      };
    case UserConstants.LOGIN_FAILURE:
      return {
        ...initialState,
        error: action.error
      };
    case UserConstants.LOGOUT:
      return {
        ...initialState,
        lastuser: state.lastUser
      };
    default:
      return state
  }
}