import React from "react";
import CardHeader from "../Card/CardHeader";
import CardText from "../Card/CardText";
import CardBody from "../Card/CardBody";
import Table from "../Table/Table";
import { dateTimeFormat} from "../../Utils";
import Button from "../CustomButtons/Button";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import classNames from "classnames";
import Switch from "@material-ui/core/Switch";
import {updatePublishing, addPublishing, removePublishing, deactivateAllPublishing} from "actions/vehiclesAction";
import { connect } from "react-redux";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CardIcon from "../Card/CardIcon";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import { Timer, TimerOff, Delete } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import SweetAlert from "react-bootstrap-sweetalert";

class Publishing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: undefined
    }
  }

  handleToggleActive(event) {
    let pub = this.props.vehicle.publishing.find(pub => pub.id === event.target.value);
    pub.active = event.target.checked;
    this.props.updatePublishing(pub, this.props.vehicle);
  }

  handleAdd() {
    const { start, end } = this.state;
    if(start !== undefined) {
      this.props.addPublishing(start, end);
    }
  }

  handleNow() {
    this.props.addPublishing(Datetime.moment(), undefined, true); // activate immediately
  }

  handleStop() {
      this.props.deactivateAllPublishing(this.props.vehicle);
  }

  handleConfirm(pub) {
    this.props.removePublishing(pub);
    this.closeAlert();
  }

  closeAlert() {
    this.setState({alert: undefined});
  }

  handleDelete(pub) {
    console.log("handleDelete", pub);
    const { classes } = this.props;
    this.setState({
      alert:
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => this.handleConfirm(pub)}
          onCancel={() => this.closeAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="Yes, remove it!"
          cancelBtnText="Cancel"
          showCancel
        >
          You will not be able to recover this imaginary file!
        </SweetAlert>
      });
  }

  handleChange(name, datetime) {
    this.setState({
      [name]:datetime
    });
    console.log("Time Changed:", datetime);
  }

  formatPublishingDate(datestring) {
    if(datestring)
      return dateTimeFormat(new Date(Date.parse(datestring)));
    else
      return "-";
  }

  render() {
    const { classes, vehicle } = this.props;
    const { alert } = this.state;
    return (
      <Card>
        <CardHeader text>
          <CardText color="primary">
            <h4 className={classes.cardTitleWhite}>Publishing</h4>
            <h4 className={classes.cardCategoryWhite}>Select or add dates when to publish</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          {alert}
          { vehicle.publishing.length > 0 &&
            <Table
              hover
              tableHeaderColor="warning"
              tableHead={["ID", "Enable", "Disable", "Action"]}
              tableData={vehicle.publishing.map((pub, key) => [
                key,
                this.formatPublishingDate(pub.enable),
                this.formatPublishingDate(pub.disable),
                <div>
                  <Switch
                    checked={pub.active}
                    onChange={event => this.handleToggleActive(event)}
                    value={pub.id}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                  <Tooltip
                    id="tooltip-top-start"
                    title="Remove"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton aria-label="Close" className={classes.tableActionButton} onClick={this.handleDelete.bind(this, pub)}>
                      <Delete className={classNames(classes.tableActionButtonIcon, classes.close)}/>
                    </IconButton>
                  </Tooltip>
                </div>
            ])}
          />}

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card plain>
                <CardHeader color="success" icon>
                  <CardIcon color="success"><Timer /></CardIcon>
                  <h4 className={classes.cardIconTitle}>Start Time</h4>
                </CardHeader>
                <CardBody>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat="HH:mm"
                      dateFormat="DD.MM.YYYY"
                      utc={false}
                      locale="de"
                      viewFormat="DD.MM.YYYY HH:mm"
                      inputProps={{placeholder: "Select a start date"}}
                      onChange={dt => this.handleChange("start", dt)}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card plain>
                <CardHeader color="danger" icon>
                  <CardIcon color="danger"><TimerOff /></CardIcon>
                  <h4 className={classes.cardIconTitle}>Stop Time</h4>
                </CardHeader>
                <CardBody>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat="HH:mm"
                      dateFormat="DD.MM.YYYY"
                      utc={false}
                      locale="de"
                      viewFormat="DD.MM.YYYY HH:mm"
                      inputProps={{placeholder: "Select an end date"}}
                      onChange={dt => this.handleChange("end", dt)}
                    />
                  </FormControl>

                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12}>
              <Button color="success" onClick={this.handleAdd.bind(this)}>Add</Button>
              <Button color="primary" onClick={this.handleNow.bind(this)}>Publish now</Button>
              <Button color="danger" onClick={this.handleStop.bind(this)}>Stop</Button>
            </GridItem>
          </GridContainer>

        </CardBody>
      </Card>
    );
  }
}

Publishing.propTypes = {
  vehicle: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  updatePublishing: PropTypes.func.isRequired,
  addPublishing: PropTypes.func.isRequired,
  removePublishing: PropTypes.func.isRequired,
  deactivateAllPublishing: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return (
    {
      vehicle: state.data.vehicle
    });
}

export default connect(mapStateToProps,{ updatePublishing, addPublishing, removePublishing, deactivateAllPublishing })(Publishing);