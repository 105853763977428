import React from "react";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { connect } from "react-redux";
import {fetchOptions, hideCheckOption, addCheckOption} from "../../actions/vehiclesAction";
import CustomInput from "../CustomInput/CustomInput";
import CardIcon from "../Card/CardIcon";
import {Money, Backspace as IconClear, AddCircle as IconAdd, Block as IconHide} from "@material-ui/icons";
import Spinner from "../Spinner";
import Button from "../CustomButtons/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import InputAdornment from "@material-ui/core/InputAdornment";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const MAX_ITEMS = 25;
const MAX_HEIGHT = 640;

class OptionsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkOptions: (props.vehicle && props.vehicle.checkOptions) || [],
      filteredCheckOptions: (props.vehicle && props.vehicle.checkOptions) || [], // initial value
      checkFilter: "",

      available: [],
      filteredOptions: [],
      filter: "",

      showAll: false,
      showHidden: false,

      modified: false,
      isEdit: props.vehicle !== undefined
    }
    this.props.fetchOptions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.vehicle !== this.props.vehicle && this.props.vehicle/* && prevProps.vehicle.checkOptions !== this.props.vehicle.checkOptions*/) {
      this.handleReset();
    }

    // update available options based on checkoptions
    if(prevProps.options !== this.props.options || prevState.checkOptions !== this.state.checkOptions || prevState.showHidden !== this.state.showHidden) {
      this.setState({
        available: this.props.options.filter(o => !this.state.checkOptions.find(co => o.id === co.key.id) && o.nameEn !== "" && (this.state.showHidden || !o.hidden))
      })
    }

      // update checkoptions based on filter
    if(prevState.checkOptions !== this.state.checkOptions || prevState.checkFilter !== this.state.checkFilter) {
      //console.log("update filtered check options");
      this.setState({
        filteredCheckOptions: this.state.checkOptions.filter(o => o.key.nameEn.toLowerCase().search(this.state.checkFilter.toLowerCase()) !== -1)
      });
    }

    // update available options based on filter
    if(prevState.available !== this.state.available || prevState.filter !== this.state.filter) {
      //console.log("componentDidUpdate 1");
      this.setState({
        filteredOptions: this.state.available.filter(o => o.nameEn.toLowerCase().search(this.state.filter.toLowerCase()) !== -1)
      });
    }

    if(this.state.isEdit && !this.state.modified && prevState.checkOptions !== this.state.checkOptions && this.state.checkOptions !== this.props.vehicle.checkOptions) {
      //console.log("componentDidUpdate 2");
      this.setState({
        modified: true
      });
    }
  }

  handleUpdate() {
    this.props.update(this.state.checkOptions);
  }

  handleUnassign(value) {
    this.setState({checkOptions: this.state.checkOptions.filter(o => o.key.id !== value.id)});
  }

  handleReset() {
    this.setState({
      checkOptions: this.props.vehicle.checkOptions,
      modified: false
    });
  }

  handleFilter(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  handleAssign(value) {
    this.setState({
      checkOptions:
      [...this.state.checkOptions, {
        key: value,
        value: true,
        id: this.props.vehicle.id
      }]
    })
  }

  handleToggle(e) {
    this.setState({
      ...this.state,
      [e.target.id]: !this.state[e.target.id]
    })
  }

  clearCheckFilter() {
    this.setState({
      checkFilter: ""
    });
  }

  clearFilter() {
    this.setState({
      filter: ""
    });
  }

  render() {
    const { classes, embedded } = this.props;
    const { filteredCheckOptions, filteredOptions, modified, filter, checkFilter, showAll, showHidden } = this.state;
    if(this.props.vehicle === undefined)
      return <Spinner/>;

    //console.log("CheckOptions", this.state);

    return (
      <Card plain={embedded}>
        <CardHeader color={"primary"} icon>
          <CardIcon color={"primary"}>
            <Money />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Options List</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={6}>
              <GridContainer>
                <GridItem xs={12}>
                  <CustomInput
                    id="checkFilter"
                    labelText="Filter"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: checkFilter,
                      onChange: this.handleFilter.bind(this),
                      endAdornment: (<InputAdornment position="end">
                          <Button justIcon disabled={checkFilter === ""} color="transparent" onClick={this.clearCheckFilter.bind(this)}>
                            <IconClear />
                          </Button>
                        </InputAdornment>)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} style={{maxHeight: MAX_HEIGHT, overflow: 'auto'}}>
              <List dense style={{maxHeight: '100%', overflow: 'auto'}}>
              {filteredCheckOptions.map((o, i) =>
                <ListItem key={i} role={undefined} dense button onClick={this.handleUnassign.bind(this, o.key)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={o.value}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText id={i} primary={o.key.nameEn} />
                </ListItem>
              )}
              </List>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={6}>
              <GridContainer>
                <GridItem xs={12}>
                <CustomInput
                  id="filter"
                  labelText="Filter or add"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value: filter,
                    onChange: this.handleFilter.bind(this),
                    endAdornment: (<InputAdornment position="end">
                      <Button justIcon disabled={filter === ""} color="transparent" onClick={this.props.addCheckOption.bind(this, filter)}>
                        <IconAdd />
                      </Button>
                      <Button justIcon disabled={filter === "" && filteredOptions.length > 10} color="transparent" onClick={this.clearFilter.bind(this)}>
                        <IconClear />
                      </Button>
                    </InputAdornment>)
                  }}
                />
                </GridItem>
                <GridItem xs={12} style={{maxHeight: MAX_HEIGHT, overflow: 'auto'}}>
                  <List dense style={{maxHeight: '100%', overflow: 'auto'}}>
                    {filteredOptions.slice(0, showAll ? filteredOptions.length : MAX_ITEMS).map((o, i) =>
                      <ListItem key={i} role={undefined} dense button onClick={this.handleAssign.bind(this, o)}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={false}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText id={i} primary={o.nameEn} />
                        <ListItemSecondaryAction>
                          {!o.hidden &&
                            <IconButton edge="end" aria-label="hide" onClick={this.props.hideCheckOption.bind(this, o)}>
                              <IconHide/>
                            </IconButton>
                          }
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </List>
                </GridItem>
                <GridItem xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="showHidden"
                        checked={showHidden}
                        tabIndex={-1}
                        onClick={this.handleToggle.bind(this)}
                        checkedIcon={
                          <Check className={classes.checkedIcon} />
                        }
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="show hidden"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="showAll"
                        checked={showAll}
                        tabIndex={-1}
                        onClick={this.handleToggle.bind(this)}
                        checkedIcon={
                          <Check className={classes.checkedIcon} />
                        }
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="show all"
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              {!embedded &&<Button disabled={!modified} color="success" onClick={this.handleUpdate.bind(this)}>Update option list</Button>}
              {!embedded &&<Button disabled={!modified} onClick={this.handleReset.bind(this)}>Reset</Button>}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

OptionsList.propTypes = {
  vehicle: PropTypes.object,
  classes: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  embedded: PropTypes.bool,
  options: PropTypes.array,
  fetchOptions: PropTypes.func.isRequired,
  addCheckOption: PropTypes.func.isRequired,
  hideCheckOption: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return (
    {
      options: state.data.options
    });
}

export default connect(mapStateToProps,{ fetchOptions, addCheckOption, hideCheckOption })(OptionsList);

