import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
// core components
import Wizard from "components/Wizard/Wizard.js";

import Step1 from "./Steps/Step1.js"
import Step2 from "./Steps/Step2.js";
import Step3 from "./Steps/Step3.js";

import {fetchBrands, fetchModels, initVehicle, fetchConfigData, assignSalesContact} from "actions/vehiclesAction";
import {updateVehicle} from "../../actions/vehiclesAction";

class AddWizard extends Component {
  render() {
    const { data } = this.props;
    const { vehicle } = data;
    return (
      <Wizard
        validate
        steps={[
          {stepName: "Basic Data", stepComponent: Step1, stepId: "start",
            props: {
              initVehicle: this.props.initVehicle.bind(this),
              vehicle,
              assignSalesContact: this.props.assignSalesContact.bind(this)
            }
          },
          {stepName: "Details", stepComponent: Step2, stepId: "details",
            props: {
              updateVehicle: this.props.updateVehicle.bind(this),
              vehicle,
            }},
          {stepName: "Sales", stepComponent: Step3, stepId: "sales",
            props: {
              assignSalesContact: this.props.assignSalesContact.bind(this),
              vehicle
            }},
        ]}
        title="Add a new vehicle"
        subtitle="You will be guided quickly through the important steps."
        finishButtonClick={e => alert("Vehicle Sucessfully Saved.")}
        color="primary"
      />
    );
  }
}

AddWizard.propTypes = {
  fetchBrands: PropTypes.func.isRequired,
  assignSalesContact: PropTypes.func.isRequired,
  updateVehicle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  data: state.data
});

export default connect(mapStateToProps, { fetchBrands, fetchModels, initVehicle, fetchConfigData, assignSalesContact, updateVehicle })(AddWizard)
