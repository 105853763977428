import React from "react";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import {ColorLens as IconColor} from "@material-ui/icons";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle";
import {makeStyles} from "@material-ui/core/styles";
import CardIcon from "../Card/CardIcon";
import CustomInput from "../CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import {database_request_url} from "../../store";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Verification from "./Steps/Verification";
import Button from "../CustomButtons/Button";

const useStyles = makeStyles(styles);
function BodyColorCard(props) {
  const [color, setColor] = React.useState("");
  const [colorManufacturer, setColorManufacturer] = React.useState("");
  const [ok, setOK] = React.useState(false);
  const [metallic, setMetallic] = React.useState(false);
  const [colors, setColors] = React.useState({});
  const [preselect, setPreselect] = React.useState('');
  const [modified, setModified] = React.useState(false);

  const isEdit = props.vehicle !== undefined;

  async function fetchData() {
    const res = await fetch(database_request_url + "bodycolors");
    res.json()
      //.then(res => { console.log(res); return res; })
      .then(res => setColors(res))
      .catch(err => console.log(err));
  }

  React.useEffect(() => {
      fetchData();
  }, []); // [] dependencies to fetch the data only once.

  React.useEffect(() => {
    props.finishFunc(!isEdit && ok && {
      name: color,
      description: colorManufacturer,
      metallic: metallic,
    });
  }, [ok, color, colorManufacturer, metallic]);

  React.useEffect(() => {
    if(colors && colors.length > 0 && props.vehicle) {
      handleReset();
    }
  }, [colors, props.vehicle]);

  React.useEffect(() => {
    // search modified color in existing colors:
    const existing_color = colors && colors.length > 0 && colors.find(c => c.name === color && c.description === colorManufacturer && c.metallic === metallic);
    if(existing_color)
      handlePreselect(existing_color)
    //else
    //  setPreselect("");
  }, [colors]);

  React.useEffect(() => {
    //console.log("change color")
  }, [color, colorManufacturer, metallic])

  const handleChangeColor = value => {
    setColor(value);
    setOK(Verification.verifyLength(value, 1));
  }

  const handleChangeColorManufacturer = value => {
    setColorManufacturer(value);
  }

  const handleToggleMetallic = () => {
    setMetallic(!metallic);
  }

  const handlePreselect = value => {
    setPreselect(value);
    setColor(value.name);
    setColorManufacturer(value.description);
    setMetallic(value.metallic);
    setOK(true);
  }

  const handleReset = () => {
    //console.log("Colors", colors);
    const selected_color = props.vehicle.colors.find(c => c.type === 0);
    const current_color = colors.find(col => col.id === selected_color.id);
    //console.log("Body Color", current_color);
    handlePreselect(current_color);
  }

  const handleUpdate = () => {
    console.log("handleUpdate");
  }

  const classes = useStyles();
  //console.log("colorManufacturer", colorManufacturer);
  const { embedded } = props;

  return (
    <Card plain={embedded}>
      <CardHeader color={ok ? "success" : "primary"} icon>
        <CardIcon color={ok ? "success" : "primary"}>
          <IconColor />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Body Color</h4>
        <p className={classes.infoText}>You can select an already existing color, or you can define a new color.</p>
      </CardHeader>
      <CardBody>
        <GridContainer>
          {colors.length > 0 &&
          <GridItem xs={12} sm={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="color-select" className={classes.selectLabel}>Preselect one of the available Colors</InputLabel>
              <Select MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      value={preselect}
                      onChange={event => handlePreselect(event.target.value)}
                      inputProps={{ name: "colorSelect", id: "color-select" }}
              >
                <MenuItem disabled classes={{root: classes.selectMenuItem}}>You can select a color from the list and apply it to your vehicle</MenuItem>
                {colors.map((v, key) =>
                  <MenuItem key={key} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={v}>
                    {v.name} ({v.description}{v.metallic && ", metallic"})
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </GridItem>}
        </GridContainer>
        <GridContainer>
          <GridItem xs={5}>
            <CustomInput
              success={ok}
              error={color !== "" && !ok}
              labelText={<span>Color - Simple name for filtering<small>(required)</small></span>}
              id="color"
              formControlProps={{fullWidth: true}}
              inputProps={{
                value: color || "",
                onChange: event => handleChangeColor(event.target.value),
                endAdornment: (
                  <InputAdornment position="end" className={classes.inputAdornment}>
                    <IconColor className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
          </GridItem>
          <GridItem xs={5}>
            <CustomInput
              //error={colorManufacturer !== "" && !ok}
              labelText={<span>Color - Manufacturer Name</span>}
              id="colorManufacturer"
              formControlProps={{fullWidth: true}}
              inputProps={{
                onChange: event => handleChangeColorManufacturer(event.target.value),
                value: colorManufacturer || "",
                endAdornment: (
                  <InputAdornment position="end" className={classes.inputAdornment}>
                    <IconColor className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
          </GridItem>
          <GridItem xs={2}>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={metallic}
                    tabIndex={-1}
                    onClick={() => handleToggleMetallic()}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Metallic"
              />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            {!embedded &&<Button color="success" disabled={!modified} onClick={() => handleUpdate()}>Update Color</Button>}
            {!embedded &&<Button disabled={!modified} onClick={() => handleReset()}>Reset</Button>}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

BodyColorCard.propTypes = {
  finishFunc: PropTypes.func.isRequired,
  vehicle: PropTypes.object,
  classes: PropTypes.object,
  embedded: PropTypes.bool
}

export default BodyColorCard;