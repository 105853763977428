import React from "react";
// @material-ui/core components
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/styles';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardIcon from "components/Card/CardIcon.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import {
  addPublishing,
  assignLocation,
  assignSalesContact,
  deactivateAllPublishing,
  deleteVehicle,
  fetchLocations,
  fetchSalesContacts,
  fetchVehicles,
  removePublishing,
  updatePublishing
} from "../../actions/vehiclesAction";
import VehicleTable from "../../components/Table/VehicleTable";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Button from "../../components/CustomButtons/Button";
import {Public, Assignment} from "@material-ui/icons";
import SalesContactCard from "../../components/Vehicles/SalesContactCard";
import LocationCard from "../../components/Vehicles/LocationCard";
import Spinner from "../../components/Spinner";

const styles = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

class ListVehicles extends React.Component {
  state = {
    checked: []
  }

  constructor(props) {
    super(props);
    this.props.fetchVehicles();
    this.props.fetchLocations();
    this.props.fetchSalesContacts();
  }

  handleToggle = value => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({...this.state, checked: newChecked});
  };

  publishAll() {
    this.state.checked.map(vehicle => this.props.addPublishing(vehicle));
  }

  render() {
    const { classes, vehicles } = this.props;
    const { checked } = this.state;
    console.log("Selected Vehicles: ", checked);

    if(vehicles === undefined)
      return <Spinner/>

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card plain>
            <CardHeader>
              <h4 className={classes.cardTitle}>
                Common Adjustments <small> - These apply to all visible items</small>
              </h4>
            </CardHeader>
            <CardBody>

              <GridContainer>
                <GridItem md={4}>
                  <Card>
                    <CardHeader color="primary" icon>
                      <CardIcon color="primary">
                        <Public/>
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>Mass Publishing</h4>
                    </CardHeader>
                    <CardBody>
                      <form>
                        <p>Publish or unpublish all visible items</p>
                        <Button color="success"
                                onClick={this.publishAll.bind(this)}
                                disabled={checked.length === 0}>
                          Publish {checked.length > 0 && <strong>&nbsp; {checked.length} Vehicles</strong>}
                        </Button>
                        <Button color="warning" disabled={true || checked.length === 0}>Unpublish {checked.length > 0 && <strong>&nbsp; {checked.length} Vehicles</strong>}</Button>
                      </form>
                    </CardBody>
                  </Card>
                </GridItem>

                <GridItem md={4}>
                  <SalesContactCard classes={classes} selected={vehicles.filter(v => checked.indexOf(v.id) !== -1)} />
                </GridItem>
                <GridItem md={4}>
                  <LocationCard classes={classes} selected={vehicles.filter(v => checked.indexOf(v.id) !== -1)} />
                </GridItem>
              </GridContainer>

            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Vehicles
                <small> - Your active vehicles</small>
              </h4>

            </CardHeader>
            <CardBody>
              <VehicleTable
                vehicles={this.props.vehicles}
                salesContacts={this.props.salesContacts}
                locations={this.props.locations}
                addPublishing={this.props.addPublishing}
                deactivateAllPublishing={this.props.deactivateAllPublishing}
                deleteVehicle={this.props.deleteVehicle}
                handleToggleOne={this.handleToggle.bind(this)}/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

ListVehicles.propTypes = {
  vehicles: PropTypes.array,
  fetchVehicles: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  fetchSalesContacts: PropTypes.func.isRequired,
  deactivateAllPublishing: PropTypes.func.isRequired,
  addPublishing: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return (
    {
      vehicles: state.data.vehicles,
      salesContacts: state.data.salesContacts,
      locations: state.data.locations
    });
}

export default connect(
  mapStateToProps,{
    fetchVehicles,
    fetchLocations,
    fetchSalesContacts,
    assignSalesContact,
    assignLocation,
    updatePublishing,
    addPublishing,
    removePublishing,
    deactivateAllPublishing,
    deleteVehicle
  })
(withStyles(styles)(ListVehicles));
