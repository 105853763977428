import Dashboard from "views/Dashboard/Dashboard";
import ErrorPage from "views/Pages/ErrorPage";
import LoginPage from "views/Pages/LoginPage";
import LockPage from "views/Pages/LockScreenPage";
import AddVehicle from "views/Vehicles/AddVehicle";
import ListVehicles from "views/Vehicles/ListVehicles";
import EditVehicle from "views/Vehicles/EditVehicle";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/add",
    name: "Add Vehicle",
    icon: "directions_car",
    component: AddVehicle,
    layout: "/admin"
  },
  {
    path: "/list",
    name: "Vehicle List",
    icon: "list",
    component: ListVehicles,
    layout: "/admin"
  },
  {
    path: "/edit",
    name: "Vehicle Edit",
    icon: "list",
    component: EditVehicle,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/login",
    name: "Login Page",
    mini: "L",
    component: LoginPage,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/lock",
    name: "Lock Screen Page",
    mini: "LS",
    component: LockPage,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/error",
    name: "Error Page",
    mini: "E",
    component: ErrorPage,
    layout: "/auth",
    redirect: true
  }
];
export default dashRoutes;
