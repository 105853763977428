import React from "react";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { connect } from "react-redux";
import {fetchFeatures, addFeature} from "../../actions/vehiclesAction";
import CustomInput from "../CustomInput/CustomInput";
import CardIcon from "../Card/CardIcon";
import {Money, Backspace as IconClear, AddCircle as IconAdd, Block as IconHide} from "@material-ui/icons";
import Spinner from "../Spinner";
import Button from "../CustomButtons/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import InputAdornment from "@material-ui/core/InputAdornment";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const MAX_ITEMS = 25;
const MAX_HEIGHT = 640;

class FeaturesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkFeatures: (props.vehicle && props.vehicle.features) || [],
      filteredCheckFeatures: (props.vehicle && props.vehicle.features) || [], // initial value
      checkFilter: "",

      available: [],
      filteredFeatures: [],
      filter: "",

      code: "",

      showAll: false,
      showHidden: false,

      modified: false,
      isEdit: props.vehicle !== undefined
    }
    this.props.fetchFeatures();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.vehicle !== this.props.vehicle && this.props.vehicle/* && prevProps.vehicle.checkOptions !== this.props.vehicle.checkOptions*/) {
      this.handleReset();
    }

    // update available options based on checkoptions
    if(prevProps.features !== this.props.features || prevState.checkFeatures !== this.state.checkFeatures) {
      this.setState({
        available: this.props.features.filter(o => !this.state.checkFeatures.find(co => o.id === co.id))
      })
    }

      // update checkoptions based on filter
    if(prevState.checkFeatures !== this.state.checkFeatures || prevState.checkFilter !== this.state.checkFilter) {
      //console.log("update filtered check options");
      this.setState({
        filteredCheckFeatures: this.state.checkFeatures.filter(o =>
          o.name.toLowerCase().search(this.state.checkFilter.toLowerCase()) !== -1
          || o.code.toLowerCase().search(this.state.checkFilter.toLowerCase()) !== -1)
      });
    }

    // update available options based on filter
    if(prevState.available !== this.state.available || prevState.filter !== this.state.filter) {
      //console.log("componentDidUpdate 1");
      this.setState({
        filteredFeatures: this.state.available.filter(o =>
          o.name.toLowerCase().search(this.state.filter.toLowerCase()) !== -1
          || o.code.toLowerCase().search(this.state.checkFilter.toLowerCase()) !== -1)
      });
    }

    if(this.state.isEdit && !this.state.modified && prevState.checkFeatures !== this.state.checkFeatures && this.state.checkFeatures !== this.props.vehicle.features) {
      //console.log("componentDidUpdate 2");
      this.setState({
        modified: true
      });
    }
  }

  handleUpdate() {
    this.props.update(this.state.checkFeatures);
  }

  handleUnassign(value) {
    this.setState({checkFeatures: this.state.checkFeatures.filter(o => o.id !== value.id)});
  }

  handleReset() {
    this.setState({
      checkFeatures: this.props.vehicle.features,
      modified: false
    });
  }

  handleFilter(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  handleAssign(value) {
    this.setState({
      checkFeatures:
      [...this.state.checkFeatures, {
        ... value
      }]
    })
  }

  handleToggle(e) {
    this.setState({
      ...this.state,
      [e.target.id]: !this.state[e.target.id]
    })
  }

  clearFilter(filter) {
    this.setState({
      [filter]: ""
    });
  }

  render() {
    const { classes, embedded } = this.props;
    const { filteredCheckFeatures, filteredFeatures, modified, filter, code, checkFilter, showAll } = this.state;
    if(this.props.vehicle === undefined)
      return <Spinner/>;

    //console.log("Features", this.state);

    return (
      <Card plain={embedded}>
        <CardHeader color={"primary"} icon>
          <CardIcon color={"primary"}>
            <Money />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Features List</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={6}>
              <GridContainer>
                <GridItem xs={12}>
                  <CustomInput
                    id="checkFilter"
                    labelText="Filter"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: checkFilter,
                      onChange: this.handleFilter.bind(this),
                      endAdornment: (<InputAdornment position="end">
                          <Button justIcon disabled={checkFilter === ""} color="transparent" onClick={this.clearFilter.bind(this, "checkFilter")}>
                            <IconClear />
                          </Button>
                        </InputAdornment>)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} style={{maxHeight: MAX_HEIGHT, overflow: 'auto'}}>
              <List dense style={{maxHeight: '100%', overflow: 'auto'}}>
              {filteredCheckFeatures.map((o, i) =>
                <ListItem key={i} role={undefined} dense button onClick={this.handleUnassign.bind(this, o)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={true}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText id={i} primary={o.name} secondary={o.code}/>
                </ListItem>
              )}
              </List>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={6}>
              <GridContainer>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    id="code"
                    labelText="Code"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: code,
                      onChange: this.handleFilter.bind(this),
                      endAdornment: (<InputAdornment position="end">
                        <Button justIcon disabled={checkFilter === ""} color="transparent" onClick={this.clearFilter.bind(this, "code")}>
                          <IconClear />
                        </Button>
                      </InputAdornment>)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={8}>
                <CustomInput
                  id="filter"
                  labelText="Filter or add"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value: filter,
                    onChange: this.handleFilter.bind(this),
                    endAdornment: (<InputAdornment position="end">
                      <Button justIcon disabled={filter === "" || code === ""} color="transparent" onClick={this.props.addFeature.bind(this, code, filter)}>
                        <IconAdd />
                      </Button>
                      <Button justIcon disabled={filter === "" && filteredFeatures.length > 10} color="transparent" onClick={this.clearFilter.bind(this, "filter")}>
                        <IconClear />
                      </Button>
                    </InputAdornment>)
                  }}
                />
                </GridItem>
                <GridItem xs={12} style={{maxHeight: MAX_HEIGHT, overflow: 'auto'}}>
                  <List dense style={{maxHeight: '100%', overflow: 'auto'}}>
                    {filteredFeatures.slice(0, showAll ? filteredFeatures.length : MAX_ITEMS).map((o, i) =>
                      <ListItem key={i} role={undefined} dense button onClick={this.handleAssign.bind(this, o)}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={false}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText id={i} primary={o.name} secondary={o.code}/>
                      </ListItem>
                    )}
                  </List>
                </GridItem>
                <GridItem xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="showAll"
                        checked={showAll}
                        tabIndex={-1}
                        onClick={this.handleToggle.bind(this)}
                        checkedIcon={
                          <Check className={classes.checkedIcon} />
                        }
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label={`show all (${filteredFeatures.length})`}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              {!embedded &&<Button disabled={!modified} color="success" onClick={this.handleUpdate.bind(this)}>Update feature list</Button>}
              {!embedded &&<Button disabled={!modified} onClick={this.handleReset.bind(this)}>Reset</Button>}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

FeaturesList.propTypes = {
  vehicle: PropTypes.object,
  classes: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  embedded: PropTypes.bool,
  features: PropTypes.array,
  fetchFeatures: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return (
    {
      features: state.data.features
    });
}

export default connect(mapStateToProps,{ fetchFeatures, addFeature })(FeaturesList);

