import React from "react";
import CardHeader from "../Card/CardHeader";
import CardText from "../Card/CardText";
import CardBody from "../Card/CardBody";
import Button from "../CustomButtons/Button";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Tooltip from "@material-ui/core/Tooltip";
import SweetAlert from "react-bootstrap-sweetalert";
import {SortableContainer, SortableElement, sortableHandle} from "react-sortable-hoc";
import arrayMove from "array-move";
import { Edit, Refresh, Delete as IconDelete, List as IconList, ViewModule as IconGrid, DragIndicator as IconDrag } from "@material-ui/icons";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import styles from "assets/jss/material-dashboard-pro-react/views/imageViewerStyle";
import {makeStyles} from "@material-ui/core/styles";
import { removeImage, updateImageOrder } from "actions/imagesAction";
import ImageUpload from "../CustomUpload/ImageUpload";

const useStyles = makeStyles(styles);
function ImageViewer(props) {
  const [alert, setAlert] = React.useState();
  const [view, setView] = React.useState("list");
  const [images, setImages] = React.useState(props.vehicle.images);
  const [modified, setModified] = React.useState(false);

  React.useEffect(() => { // When images are reloaded from the Server, update the list
    setImages(props.vehicle.images);
  }, [props.vehicle.images]);

  React.useEffect(() => { // when sorting has changed, update on the server
    if(modified && images !== props.vehicle.images) {
      console.log("Images are different");
      props.updateImageOrder(images);
      setModified(false);
    }
  }, [modified, images, props.vehicle.images]);

  const closeAlert = () => {
    setAlert(undefined);
  }

  const handleRemove = (image) => {
    console.log("handleDelete", image);
    const { classes } = props;
    setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => handleConfirm(image)}
          onCancel={() => closeAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="Yes, remove it!"
          cancelBtnText="Cancel"
          showCancel
        >
          You will not be able to recover this file!
        </SweetAlert>
      );
  }

  const handleConfirm = (image) => {
    props.removeImage(image);
    closeAlert();
  }

  const getPreviewUrl = (image, width = 300, height = 300) => {
    return "http://ecars-vehicles.s3-website.eu-central-1.amazonaws.com/_processed/" +
      image.folder + "/" +
      width + "x" + height + "_" +
      image.s3Name;
  }

  const getCloudImageURL = vehicle => {
    return "https://s3.console.aws.amazon.com/s3/buckets/ecars-vehicles/uploads/" + vehicle.id.substring(0, 8) + "/";
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    setImages(prevState =>
      arrayMove(prevState, oldIndex, newIndex)
    );
    setModified(true);
  };

  const SortableItem = SortableElement(({value}) =>
    <ListItem className={classes.listItem}>
      <ListItemAvatar className={classes.listImage}>
          <>
            <DragHandle/>
            <img src={getPreviewUrl(value)} alt={value.name} width="100px"/>
          </>
      </ListItemAvatar>
      <ListItemText
        primary={value.name}
        secondary={`${(value.filesize / 1024).toLocaleString(undefined, {maximumFractionDigits:2})  } kBytes`}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" onClick={() => handleRemove(value)}>
          <IconDelete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );

  const SortableList = SortableContainer(({items}) => {
    return (
      <List dense>
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} value={value} />
        ))}
      </List>
    );
  });

  const DragHandle = sortableHandle(() => <IconDrag/>);

  const { vehicle } = props;
  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary" text>
        <CardText color="primary">
          <h4 className={classes.cardTitleWhite}>Images</h4>
        </CardText>
      </CardHeader>
      <CardBody>
            <ImageUpload classes={classes} vehicle={vehicle} />
      </CardBody>
      <CardBody>
        {alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card plain>
              <CardHeader>
                <Button justIcon onClick={() => setView("list")} color={view === "list" ? "primary" : "white"} disabled={images.length === 0}>
                  <IconList/>
                </Button>
                <Button justIcon onClick={() => setView("grid")} color={view === "grid" ? "primary" : "white"} disabled={images.length === 0}>
                  <IconGrid/>
                </Button>
                <Button disabled={true} color="transparent">
                  {images.length} Images uploaded
                </Button>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            {images.length > 0 && view === "list" &&
            <Card plain className={classNames(classes.cardHover, classes.picture)}>
              <SortableList items={images} onSortEnd={onSortEnd} useDragHandle/>
            </Card>
            }
          </GridItem>

          { images.length > 0 && view === "grid" && images.map((image, key) =>
            <GridItem xs={12} sm={6} md={4} key={key}>
              <Card plain className={classNames(classes.cardHover, classes.picture)}>
                <CardHeader image className={classNames(classes.cardHeaderHover, classes.cardHeader)}><img src={getPreviewUrl(image)} alt={image.name} /></CardHeader>
                <CardBody>
                  <div className={classes.cardHoverUnder}>
                    <Tooltip id="tooltip-top" title="View" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                      <Button color="transparent" simple justIcon>
                        <Refresh className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                    <Tooltip id="tooltip-top" title="Edit Description" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                      <Button color="success" simple justIcon>
                        <Edit className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                    <Tooltip id="tooltip-top" title="Remove" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                      <Button color="danger" simple justIcon onClick={() => handleRemove(image)}>
                        <IconDelete className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                    <h6>{image.name}</h6>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          )}
          { images.length > 0 &&
            <GridItem xs={12}>
              <p><a href={getCloudImageURL(vehicle)} target="_blank" rel="noopener noreferrer">See Images on AWS Cloud</a></p>
            </GridItem>
          }

        </GridContainer>

      </CardBody>
    </Card>
  );

}

ImageViewer.propTypes = {
  vehicle: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  removeImage: PropTypes.func.isRequired,
  updateImageOrder: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return (
    {
      //vehicle: state.data.vehicle
    });
}

export default connect(mapStateToProps,{ removeImage, updateImageOrder })(ImageViewer);