import {Route} from "react-router-dom";
import React from "react";

export default class LayoutUtility {

  static getActiveRoute(routes) {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = LayoutUtility.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  }

  static getRoutes(base, routes) {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return LayoutUtility.getRoutes(base, prop.views);
      }
      if (prop.layout === base) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

}