/**
 *
 * @param num
 * @param digits
 * @returns {string}
 */
export function ccyFormat(num, currency = "EUR", digits = 2) {
  return num.toLocaleString("de-DE", {
    minimumFractionDigits: digits,
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
    useGrouping: true,
    maximumFractionDigits: digits
  });
}

export function percFormat(num, digits = 2) {
  return num.toLocaleString("de-DE", {
    style: "percent",
    maximumFractionDigits: digits
  });
}

export function dateFormat(date) {
  return date.toLocaleString("de-DE", { year: 'numeric', month: 'long', day: 'numeric' });
}

export function dateTimeFormat(date) {
  return date.toLocaleString("de-DE", { year: 'numeric', month: 'long', day: 'numeric', hour12: false, hour: 'numeric', minute:'numeric' });
}


/**
 *
 * @param num
 * @returns {string}
 */
export function timeFormat(num) {
  let hours = Math.floor(num / 60);
  let minutes = num % 60;
  return (hours > 0 ? hours + "\u00A0h\u00A0" : "") + minutes + "\u00A0min";
}

export function powerFormat(kw) {
  let ps = Math.ceil(kw * 1.35962);
  return (kw > 0 ? kw + "\u00A0kW\u00A0/\u00A0" + ps + "\u00A0PS" : " - ");
}

export function co2Format(co2) {
  return co2 > 0 ? co2 + "\u00A0g/km" : " - ";
}

export function consumptionFormatCombined(i, o, c) {
  return i + "\u00A0/\u00A0" + o + "\u00A0/\u00A0" + c + "\u00A0L/100km";
}