import { combineReducers } from "redux";

import vehiclesReducer from "./vehiclesReducer";
import authenticationReducer from "./authenticationReducer";
import imagesReducer from "./imagesReducer";

export default combineReducers({
  data: vehiclesReducer,
  auth: authenticationReducer,
  images: imagesReducer
});
