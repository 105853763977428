import React from "react";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { withStyles} from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Store from "@material-ui/icons/Store";
import DateRange from "@material-ui/icons/DateRange";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { List } from "@material-ui/icons";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import PropTypes from "prop-types";
import {connect} from "react-redux";
import {fetchLocations, fetchVehicles} from "../../actions/vehiclesAction";
import Spinner from "../../components/Spinner";
import {ccyFormat} from "../../Utils";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.props.fetchVehicles();
    this.state = {
      error: null,
      isLoaded: false,
      addresses: []
    };

    this.props.fetchLocations();
  }

  getLocationsTableData(locations) {
    const address_table = locations.map(a => {
      const vehicles = this.props.vehicles.filter(v => v.location === a.id);
      const online = vehicles.filter(v => v.published);

      return [
        <img src={a.marker} alt={a.title} key={a.id}/>,
        a.country,
        a.title,
        online.length,
        vehicles.length
      ];
    });
    return address_table;
  }

  render() {
    const {vehicles, locations, classes} = this.props;
    console.log("Dashboard", vehicles);

    if(vehicles === undefined || locations === undefined) {
      return <Spinner/>
    }

    const addressTable = this.getLocationsTableData(locations);
    const online = vehicles.filter(v => v.published);
    const value = vehicles.reduce((prev, curr) => curr.price.gross + prev, 0);
    const mapData = locations.reduce((da,  a) => {
      return { ...da, [a.country]: (da[a.country]? da[a.country] : 0)
           + this.props.vehicles.filter(v => v.location === a.id).length
        }
      }, { });

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>content_copy</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Vehicles Online</p>
                <h3 className={classes.cardTitle}>{online.length}/{vehicles.length}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <a href="list"><List/>Go To List</a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Store/>
                </CardIcon>
                <p className={classes.cardCategory}>Value</p>
                <h3 className={classes.cardTitle}>{ccyFormat(value)}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange/>
                  Total stock value
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Language/>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Offers by Locations
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={5}>
                    <Table tableData={addressTable}/>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <VectorMap
                      map={"europe_mill"}
                      backgroundColor="transparent"
                      zoomOnScroll={false}
                      containerStyle={{
                        width: "100%",
                        height: "280px"
                      }}
                      containerClassName="map"
                      regionStyle={{
                        initial: {
                          fill: "#e4e4e4",
                          "fill-opacity": 0.9,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0
                        }
                      }}
                      series={{
                        regions: [
                          {
                            values: mapData,
                            scale: ["#AAAAAA", "#444444"],
                            normalizeFunction: "polynomial"
                          }
                        ]
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/*<h3>Latest Vehicles</h3>
        <br/>
        <GridContainer>

        </GridContainer>*/}
      </div>
    );
  }
}

Dashboard.propTypes = {
  vehicles: PropTypes.array,
  fetchVehicles: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return (
    {
      vehicles: state.data.vehicles,
      locations: state.data.locations
    });
}

export default connect(mapStateToProps,{ fetchVehicles, fetchLocations })(withStyles(styles)(Dashboard));