export const VehicleConstants = {
  FETCH_VEHICLES: "FETCH_VEHICLES",
  FETCH_VEHICLE: "FETCH_VEHICLE",
  FETCH_BRANDS: "FETCH_BRANDS",
  FETCH_LOCATIONS: "FETCH_LOCATIONS",
  FETCH_SALESCONTACTS: "FETCH_SALESCONTACTS",
  FETCH_CLASSES: "FETCH_CLASSES",
  FETCH_MODELS: "FETCH_MODELS",
  INIT_VEHICLE: "INIT_VEHICLE",
  UPDATE_VEHICLE: "UPDATE_VEHICLE",
  FETCH_CONFIG: "FETCH_CONFIG",
  BEGIN_ADD_VEHICLE: "BEGIN_ADD_VEHICLE",
  ERROR_VEHICLE: "ERROR_VEHICLE",
  FETCH_OPTIONS: "FETCH_OPTIONS",
  FETCH_FEATURES: "FETCH_FEATURES"
};

export const ImagesConstants = {
  UPLOAD_IMAGE_REQUEST: "UPLOAD_IMAGE_REQUEST",
  UPLOAD_IMAGE_RESPONSE: "UPLOAD_IMAGE_RESPONSE",
  DELETE_IMAGE: "DELETE_IMAGE",
  UPLOAD_IMAGE_ERROR: "UPLOAD_IMAGE_ERROR",
  IMAGE_ERROR_CLEAR: "IMAGE_ERROR_CLEAR"
}

export const PublishingConstants = {
  UPDATE_PUBLISHING: "UPDATE_PUBLISHING",
  ADD_PUBLISHING: "ADD_PUBLISHING",
  DELETE_PUBLISHING: "DELETE_PUBLISHING"
};

export const AlertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR'
};

export const UserConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE'
};
