/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { store } from "./store"; // https://itnext.io/react-native-why-you-should-be-using-redux-persist-8ad1d68fa48b
import { Provider } from "react-redux";
import { LocalizeProvider } from "react-localize-redux";

import AuthLayout from "layouts/Auth";
import AdminLayout from "layouts/Admin";
import NotFoundLayout from "layouts/NotFound";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

export const history = createBrowserHistory();
ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider>
      <Router history={history}>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <Route path="/admin" component={AdminLayout} />
          <Route component={NotFoundLayout} />
        </Switch>
      </Router>
    </LocalizeProvider>
  </Provider>,
  document.getElementById("root")
);
