import React from "react";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Button from "../CustomButtons/Button";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "../CustomInput/CustomInput";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardIcon from "../Card/CardIcon";
import {Money} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {database_request_url} from "../../store";
import { isEqual } from 'lodash';

const currencies = [
  {symbol: "EUR", name: "Euro - Default"},
  {symbol: "CZK", name: "Czech"},
  {symbol: "HUF", name: "Hungary"}
  ];

class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: (props.vehicle && props.vehicle.price) || {
        gross: 0,
        net: 0,
        foreignGross: 0,
        foreignNet: 0,
        currency: "EUR",
        vat: 20.0,
        nova: 32.0,
        hasNova: true,
        vatReclaimable: false
      },
      modified: false,
      isEdit: props.vehicle !== undefined
    }
  }

  /** on a new vehicle update the prices **/
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.vehicle !== this.props.vehicle && this.props.vehicle) {
      this.handleReset();
    }

    if(this.props.embedded && prevState.price !== this.state.price) {
        this.props.updatePrice(this.props.vehicle, this.state.price);
    }

    if(this.state.isEdit && prevState.price !== this.state.price && !isEqual(this.state.price, this.props.vehicle.price)) {
      this.setState({
        modified: true
      });
    }

  }

  handleUpdate() {
    this.props.updatePrice(this.props.vehicle.id, this.state.price);
  }

  handleReset() {
    this.setState({
      ...this.state,
      price: this.props.vehicle.price,
      modified: false
    });
  }

  // https://upmostly.com/tutorials/react-onchange-events-with-examples
  handleChange(e) {
    this.setState({
      ...this.state,
      price: {
        ...this.state.price,
        [e.target.id]:e.target.value
      }
    });
  }

  handleValidateNumber(e) {
    this.setState({
      ...this.state,
      price: {
        ...this.state.price,
        [e.target.id]: Number(e.target.value)
      }
    });
  }


  handleToggle(e) {
    this.setState({
      ...this.state,
      price: {
        ...this.state.price,
        [e.target.id]: !this.state.price[e.target.id]
      }
    })
  }

  handleSelect(e) {
    this.setState({
      ...this.state,
      price: {
        ...this.state.price,
        [e.target.name]: e.target.value
      }
    })
  }

  undoCurrencySelection() {
    this.setState({
      ...this.state,
      price: {
        ...this.state.price,
        "currency": "EUR"
      }
    });
  }

  convertCurrency() {
    const { price } = this.state;
    fetch(database_request_url + `currency/value?source=${price.currency}&target=EUR&value=${price.foreignGross}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      .then(response => response.json())
      .then(data => {
        this.setState( {
          ...this.state,
          price: {
            ...this.state.price,
            gross: data.targetValue
          }
        });
      })
      .catch(err => {
        console.error("caught it!", err);
      });
    fetch(database_request_url + `currency/value?source=${price.currency}&target=EUR&value=${price.foreignNet}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      .then(response => response.json())
      .then(data => {
        this.setState( {
          ...this.state,
          price: {
            ...this.state.price,
            net: data.targetValue
          }
        });
      })
      .catch(err => {
        console.error("caught it!", err);
      });
  }

  render() {
    const { classes, embedded } = this.props;
    const { price, ok, modified } = this.state;

    return (
      <Card plain={embedded}>
        <CardHeader color={ok ? "success" : "primary"} icon>
          <CardIcon color={ok ? "success" : "primary"}>
            <Money />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Pricing</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={2}>
              <FormLabel className={classes.labelHorizontal}>
                <strong>EUR</strong>
              </FormLabel>
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                id="gross"
                error={price.gross <= 0}
                //success={price.gross > 0}
                labelText={<span>Brutto EUR <small>(required)</small></span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: price.gross,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this),
                  /*endAdornment: (
                  <InputAdornment position="end" className={classes.inputAdornment}>
                    <Public className={classes.inputAdornmentIcon} />
                  </InputAdornment>)*/
                }}
              />
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                id="net"
                labelText={<span>Netto EUR</span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: price.net,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={2}>
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                id="vat"
                labelText={<span>VAT %</span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: price.vat,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this),
                }}
              />
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                id="nova"
                labelText={<span>NoVa %</span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: price.nova,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this),
                }}
              />
            </GridItem>
            <GridItem xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="hasNova"
                    checked={price.hasNova}
                    tabIndex={-1}
                    onClick={this.handleToggle.bind(this)}
                    checkedIcon={
                      <Check className={classes.checkedIcon} />
                    }
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="has NoVa"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="vatReclaimable"
                    checked={price.vatReclaimable}
                    tabIndex={-1}
                    onClick={this.handleToggle.bind(this)}
                    checkedIcon={
                      <Check className={classes.checkedIcon} />
                    }
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="VAT reclaimable"
              />
            </GridItem>
          </GridContainer>
          {embedded && price.currency === "EUR" &&
            <GridContainer>
              <GridItem xs={2}></GridItem>
              <GridItem xs={10}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel htmlFor="currency-select" className={classes.selectLabel}>Select a foreign currency if required.</InputLabel>
                  <Select MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          //value={preselect}
                          onChange={this.handleSelect.bind(this)}
                          inputProps={{ name: "currency", id: "currency-select" }}
                  >
                    <MenuItem disabled classes={{root: classes.selectMenuItem}}>You can select your originate currency, to ignore please select EUR.</MenuItem>
                    {currencies.map((v, key) =>
                      <MenuItem key={key} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={v.symbol}>
                        {v.name} ({v.symbol})
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          }

          {price.currency !== "EUR" &&
          <GridContainer>
            <GridItem xs={2}>
              <FormLabel className={classes.labelHorizontal}>
                <strong>{price.currency}</strong>
              </FormLabel>
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                id="foreignGross"
                labelText={<span>Brutto {price.currency}</span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  defaultValue: price.foreignGross,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this),
                }}
              />
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                id="foreignNet"
                labelText={<span>Netto {price.currency}</span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  defaultValue: price.foreignNet,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this),
                }}
              />
            </GridItem>

          </GridContainer>}

          <GridContainer>
            <GridItem xs={12}>
              {!embedded &&<Button color="success" disabled={!modified} onClick={this.handleUpdate.bind(this)}>Update Price</Button>}
              {!embedded &&<Button disabled={!modified} onClick={this.handleReset.bind(this)}>Reset</Button>}
              {price.currency !== "EUR" &&
                <>
                  <Button color="info" disabled={price.foreignGross === 0} onClick={this.convertCurrency.bind(this)} className={"ml-auto"}>Convert <strong>&nbsp;{price.currency}&nbsp;</strong> to <strong>&nbsp;EUR&nbsp;</strong></Button>
                  <Button color="white" className={"ml-auto"} onClick={this.undoCurrencySelection.bind(this)}>Back to EUR</Button>
                </>
              }

            </GridItem>
          </GridContainer>

        </CardBody>
      </Card>
    );
  }
}

Pricing.propTypes = {
  vehicle: PropTypes.object,
  classes: PropTypes.object.isRequired,
  updatePrice: PropTypes.func.isRequired,
  embedded: PropTypes.bool
}

export default Pricing;