import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import {database_request_url} from "../../store";
import {login, logout} from "../../actions/authenticationAction";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import Spinner from "../../components/Spinner";
import PropTypes from "prop-types";
import SnackbarContent from "components/Snackbar/SnackbarContent";

const useStyles = makeStyles(styles);

const LoginPage = (props) => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [systemStatus, setSystemStatus] = React.useState(true); // simple true or false if an error occured
  const [error, setError] = React.useState();
  const [auth, setAuth] = React.useState({ username: undefined, password: undefined });

  React.useEffect(() => {
    async function checkDatasource() {
      const res = await fetch(database_request_url + "status")
        .catch(err => {
          console.log("ERROR when checking datasource", err);
          setError(err.toString());
          setSystemStatus(false);
        });
      if(!res) // TODO USE ERROR Actions and FRamework
        setSystemStatus(false);
      else
        res
          .json()
          .then(res => { console.log("status from datasource", res); setSystemStatus(res); })
          .catch(err => { console.log("error from datasource", err); setSystemStatus(false); });
    }
    checkDatasource();
  }, []);

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const handleLogin = () => {
    props.login(auth.username, auth.password);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setAuth({...auth, [name]: value})
  };

  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('user'));

  if(user && user.username && user.session) {
    console.log("Redirect to Admin Console");
    return <Redirect to={{pathname: "/admin", state: {from: "/auth/login"}}}/>;
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} md={6}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="primary">
                <h4 className={classes.cardTitle}>Log in</h4>
                <div className={classes.socialLine}>
                  {["fas fa-car", "fas fa-car-side", "fas fa-door-open"].map((prop, key) => {
                    return (
                      <Button color="transparent" justIcon key={key} className={classes.customButtonClass}>
                        <i className={prop} />
                      </Button>
                    );
                  })}
                </div>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Username"
                  id="username"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleChange.bind(this),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    name: "username"
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleChange.bind(this),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    name: "password",
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                {props.auth.error &&
                  <SnackbarContent
                    message={"ERROR - " + props.auth.error}
                    close
                    color="danger"
                  />}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {props.auth.loggingIn ?
                  <Spinner/> :
                  <Button color="primary" simple size="lg" block onClick={handleLogin.bind(this)}
                          disabled={!systemStatus}>
                    Login
                  </Button>
                }
              </CardFooter>
            </Card>
          </form>
        </GridItem>
        {!systemStatus &&
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="danger">
                <h4 className={classes.cardTitle}>ERROR</h4>
              </CardHeader>
              <CardBody>
                There are some issues: <b>{error}</b>
              </CardBody>
            </Card>
          </GridItem>
        }
      </GridContainer>
    </div>
  );
};

LoginPage.propTypes = {
  auth: PropTypes.shape({
    loggingIn: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  }),
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = store => ({
  auth: store.auth
});

export default connect(mapStateToProps, { login, logout })(LoginPage);
