import {UserConstants} from './types';
import {alertActions} from "./alertActions";
import {typo3_url} from "../store";

export const login = (username, password) => dispatch => {
  var userData = {
    'username': username,
    'userident': password,
    'login_status': 'login'
  };
console.log("login data:", userData);
  var formBody = [];
  for (var property in userData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(userData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  dispatch(request({username}));

  fetch(typo3_url + "api?type=29104", {
    mode: 'cors',
    method: 'POST',
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    body: formBody
  })
  .then(handleResponse)
  .then(data => {
    if(data.error) {
      throw new Error(data.error)
    }
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user', JSON.stringify(data.user));
    return data;
  })
  .then(
    data => {
      dispatch(success(data.user));
    },
    error => {
      dispatch(failure(error.toString()));
      dispatch(alertActions.error(error.toString()));
    }
  );
};

export const logout = () => () => {
  // remove user from local storage to log user out
  const lastUser = JSON.parse(localStorage.getItem('user'));
  localStorage.removeItem('user');
  fetch(typo3_url + "api?type=29104", {
    mode: 'cors',
    method: 'POST',
    headers: {'Set-Cookie': `be_typo_user=${lastUser.session};`}
  }).then(res => console.log("Logout response:", res));
  return { type: UserConstants.LOGOUT, lastUser };
};

function request(user) { return { type: UserConstants.LOGIN_REQUEST, user } };
function success(user) { return { type: UserConstants.LOGIN_SUCCESS, user } };
function failure(error) { return { type: UserConstants.LOGIN_FAILURE, error } };

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}