export const VerificationType = {
  EMAIL: "email",
  LENGTH: "length",
  FIXED: "fixed"
};

export const handleToggle = value => {
  const currentIndex = this.state.checked.indexOf(value);
  const newChecked = [...this.state.checked];

  if (currentIndex === -1) {
    newChecked.push(value);
  } else {
    newChecked.splice(currentIndex, 1);
  }
  this.setState({"checked": newChecked});
};


export default class Verification {
  // function that returns true if value is email, false otherwise
  static verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (emailRex.test(value));
  }

  // function that verifies if a string has a given length or not
  static verifyLength = (value, length) => value.length >= length;

  static verifyExactLength = (value, length) => value.length === length;

  static SUCCESS = "success";
  static ERROR = "success";

/*  enum CheckBoxes {
    Metallic,
    ABS
  };*/
}