import React from "react";
import PropTypes from "prop-types";

import ReactTable from "components/ReactTable/ReactTable.js";
import Spinner from "../Spinner";
import Button from "../CustomButtons/Button";
import { Public, DeleteOutline, Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import {ccyFormat} from "../../Utils";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import {withStyles} from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle"
import SweetAlert from "react-bootstrap-sweetalert";

/**
 * API https://github.com/tannerlinsley/react-table/tree/master/docs/api
 *
 */
class VehicleTable extends React.Component{
  state = {
    alert: undefined
  }

  closeAlert = () => {
    this.setState({alert: undefined});
  }

  handleDisable = (vehicle) => {
    const { classes } = this.props;
    this.setState({alert:
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => this.handleConfirm(vehicle)}
        onCancel={() => this.closeAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, remove it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this vehicle!
      </SweetAlert>}
    );
  }

  handleConfirm = (vehicle) => {
    this.props.deleteVehicle(vehicle.id);
    this.closeAlert();
  }

  handleFavorite(vehicle) {
    console.log("handleFavorite", vehicle);
  }

  handleEdit(vehicle) {
    console.log("handleEdit", vehicle);
  }

  handlePublish(vehicle) {
    console.log("handlePublish", vehicle);
    if(vehicle.published) {
      this.props.deactivateAllPublishing(vehicle);
    } else {
      // publish now
      const start = Datetime.moment();
      console.log("start", start);
      this.props.addPublishing(start, undefined, true, vehicle); // activate immediately
    }
  }

  renderLocation(location) {
    const addr = this.props.locations && this.props.locations.find(c => c.id === location);
    if(addr)
      return <small>{addr.title} ({addr.country})</small>;
    else
      return <small className={"warning"}>No location assigned.</small>;
  }

  renderColor(colors) {
    if(colors !== undefined && colors.length > 0) {
      return colors[0].name;
    } else {
      return "";
    }
  }

  renderPrice(price, contact) {
    return <>
      <strong>{ccyFormat(price.gross, "EUR")}</strong>
        {price.currency !== "EUR" && price.foreignGross > 0 && <><br/>{ccyFormat(price.foreignGross, price.currency)}</>}
        <br/>
        {this.renderSalesContact(contact)}
      </>;
  }

  renderSalesContact(contact) {
    const sc = this.props.salesContacts && this.props.salesContacts.find(c => c.uid === contact);
    if(sc)
      return <small>Sales: {sc.fullName}</small>;
    else
      return <small className={"warning"}>No sales contact assigned.</small>;
  }

  renderModel(vehicle) {
    return <>
      <strong>{vehicle.modelText}</strong> ({vehicle.model})<br/><small>VIN: {vehicle.vin}</small>
    </>
  }

  render() {
    const {vehicles, classes} = this.props;
    console.log("vehicles", vehicles);
    console.log("Table state", this.state);

    if (vehicles === undefined) {
      return <Spinner/>;
    } else {
      const rows = vehicles.map((vehicle, key) => {
        return {
          check: <Checkbox
            key={key}
            //className={classes.positionAbsolute}
            tabIndex={-1}
            onClick={() => this.props.handleToggleOne(vehicle)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />,
          brand: vehicle.brand.description,
          model: this.renderModel(vehicle),
          color: this.renderColor(vehicle.colors),
          location: this.renderLocation(vehicle.location),
          price: this.renderPrice(vehicle.price, vehicle.contact),
          actions: <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={this.handlePublish.bind(this, vehicle)}
              color={vehicle.published?"success":"danger"}
              className="like"
            >
              <Public/>
            </Button>
            {/*<Button
              justIcon
              round
              simple
              onClick={this.handleFavorite.bind(this, vehicle)}
              color="info"
              className="like"
            >
              <Favorite/>
            </Button>*/}
            <Link to={{
              pathname: "/admin/edit",
              vehicle: vehicle,
              hash: vehicle.id,
              state: { s: true }
            }}
                  onClick={this.handleEdit.bind(this, vehicle)}>
              <Button
                justIcon
                round
                simple
                color="warning"
                className="edit"
              >
                <Edit/>
              </Button>
            </Link>
            <Button
              justIcon
              round
              simple
              onClick={this.handleDisable.bind(this, vehicle)}
              color="danger"
              className="remove"
            >
              <DeleteOutline/>
            </Button>
          </div>
        }});

      return (
        <React.Fragment>
          {/*
          <Table
          striped
          tableHead={[
           "#",
            "",
            "Brand",
            "Model",
            "Color",
            "Location",
            "Price",
            "Actions"
          ]}
          tableData={data}
          />
          */}
          {this.state.alert}
          <ReactTable
            defaultPageSize={50}
            showPaginationTop
            showPaginationBottom={true}
            //rowsSelectorText={"select"}
            className="-striped -highlight"
            columns={[
              {
                Header: "" /*<Checkbox
                  key="id"
                  className={classes.positionAbsolute}
                  tabIndex={-1}
                  onClick={() => this.handleToggleAll.bind(this)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />,
                width: 30*/,
                disableFilters: true,
                disableSortBy: true,
                accessor: "check",
                defaultCanSort: false,
              },
              {
                Header: "Brand",
                accessor: "brand"
              },
              {
                Header: "Model",
                accessor: "model"
              },
              {
                Header: "Color",
                accessor: "color",//row => this.renderColor(row.colors)
              },
              {
                Header: "Location",
                accessor: "location",//row => this.renderLocation(row.location)
              },
              {
                Header: "Price",
                accessor: "price",// row => ccyFormat(row.price.gross, row.price.currency || "EUR")
              },
              {
                Header: "Actions",
                accessor: "actions",
                //disableFilters: true,
              }
            ]}
            data={rows}
            />
        </React.Fragment>
      );
    }
  }
}

VehicleTable.propTypes = {
  vehicles: PropTypes.array,
  handleToggleOne: PropTypes.func.isRequired,
  addPublishing: PropTypes.func.isRequired,
  deleteVehicle: PropTypes.func.isRequired,

  salesContacts: PropTypes.array,
  locations: PropTypes.array
};

export default withStyles(styles)(VehicleTable);