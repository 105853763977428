import {PublishingConstants, VehicleConstants} from "../actions/types";

const initialState = {
  vehicles: undefined,
  brands: undefined,
  locations: undefined,
  salesContacts: undefined,
  classes: [],
  models: undefined,
  vehicle: undefined,
  options: [],
  features: [],
  config: {
    climatization: []
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case VehicleConstants.BEGIN_ADD_VEHICLE:
      return {
        ...state,
        vehicle: undefined
      };
    case VehicleConstants.FETCH_VEHICLES:
      return {
        ...state,
        vehicles: action.payload
      };
    case VehicleConstants.FETCH_BRANDS:
      return {
        ...state,
        brands: action.payload
      };
    case VehicleConstants.FETCH_LOCATIONS:
      return {
        ...state,
        locations: action.payload
      };
    case VehicleConstants.FETCH_SALESCONTACTS:
      return {
        ...state,
        salesContacts: action.payload.addresses.map(contact => { return {
          ...contact,
          location: action.payload.locations[contact.uid] ? action.payload.locations[contact.uid] : ""
        }})
      };
    case VehicleConstants.FETCH_CLASSES:
      return {
        ...state,
        classes: action.payload
      };
    case VehicleConstants.FETCH_MODELS:
      return {
        ...state,
        models: {
          ...state.models,
          [action.brand]: action.payload.values
        }
      };
    case VehicleConstants.INIT_VEHICLE:
    case VehicleConstants.UPDATE_VEHICLE:
    case VehicleConstants.FETCH_VEHICLE:
      console.log("REDUCER VEHICLE:", action.payload);
      return {
        ...state,
        vehicle: action.payload
      };
    case PublishingConstants.UPDATE_PUBLISHING:
      return state;
    case VehicleConstants.FETCH_OPTIONS:
      return {
        ...state,
        options: action.payload
      }
    case VehicleConstants.FETCH_FEATURES:
      return {
        ...state,
        features: action.payload
      }
    default:
      return state;
  }
};
