import React from "react";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "../CustomInput/CustomInput";
import CardIcon from "../Card/CardIcon";
import {Money} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Spinner from "../Spinner";
import Button from "../CustomButtons/Button";

class Environment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      environment: (props.vehicle && props.vehicle.environment) || {
        inside: 0,
        outside: 0,
        combined: 0,
        co2: 0,
        euro: "",
        fuel: "",
        efficiency: ""
      },
      euro: [1,2,3,4,5,6],
      fuel: ["PETROL", "DIESEL", "HYBRID", "ELECTRIC"],
      efficiency: ["A+", "A", "B", "C", "D", "E", "F", "G"],
      modified: false,
      isEdit: props.vehicle !== undefined
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.vehicle !== this.props.vehicle && this.props.vehicle) {
      this.handleReset();
    }

    if(!this.state.isEdit && prevState.environment !== this.state.environment) {
      this.handleUpdate();
    }

    if(this.state.isEdit && prevState.environment !== this.state.environment && this.state.environment !== this.props.vehicle.environment) {
      this.setState({
        modified: true
      });
    }
  }

  handleUpdate() {
    //console.log("handle update");
    this.props.update(this.state.environment);
  }

  handleReset() {
    this.setState({
      ...this.state,
      environment: this.props.vehicle.environment,
      modified: false
    });
  }

  // https://upmostly.com/tutorials/react-onchange-events-with-examples
  handleChange(e) {
    this.setState({
      ...this.state,
      environment: {
        ...this.state.environment,
        [e.target.id]: e.target.value
      }
    });
  }

  handleValidateNumber(e) {
    this.setState({
      ...this.state,
      environment: {
        ...this.state.environment,
        [e.target.id]: Number(e.target.value)
      }
    });
  }

  handleToggle(e) {
    this.setState({
      ...this.state,
      environment: {
        ...this.state.environment,
        [e.target.id]: !this.state.environment[e.target.id]
      }
    })
  }

  handleSelect(e) {
    this.setState({
      ...this.state,
      environment: {
        ...this.state.environment,
        [e.target.name]: e.target.value === "A+" ? "APlus" : e.target.value
      }
    })
  }

  render() {
    const { classes, embedded } = this.props;
    const { environment, modified } = this.state;
    if(this.props.vehicle === undefined || environment === undefined)
      return <Spinner/>;

    //console.log("Environemnt", this.state);

    return (
      <Card plain={embedded}>
        <CardHeader color={"primary"} icon>
          <CardIcon color={"primary"}>
            <Money />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Environmental Data</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={2}>
              <FormLabel className={classes.labelHorizontal}>
                <strong>Economy</strong>
              </FormLabel>
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                id="inside"
                error={environment.inside < 0}
                labelText={<span>Urban <small>(in L/100 km)</small></span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: environment.inside,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this)
                }}
              />
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                id="outside"
                error={environment.outside < 0}
                labelText={<span>Extra urban <small>(in L/100 km)</small></span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: environment.outside,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this)
                }}
              />
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                id="combined"
                error={environment.combined < 0}
                labelText={<span>Combined <small>(in L/100 km)</small></span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: environment.combined,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this)
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={2}>
              <FormLabel className={classes.labelHorizontal}>
                <strong>Exhaust</strong>
              </FormLabel>
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                id="co2"
                error={environment.co2 < 0}
                labelText={<span>CO2 <small>(in g/km)</small></span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: environment.co2,
                  onChange: this.handleChange.bind(this),
                }}
              />
            </GridItem>
            <GridItem xs={4}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="euro-select" className={classes.selectLabel}>EURO Class</InputLabel>
                <Select MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        value={environment.euro === 0 ? "" : environment.euro}
                        onChange={this.handleSelect.bind(this)}
                        inputProps={{ name: "euro", id: "efficiency-select" }}
                >
                  <MenuItem disabled classes={{root: classes.selectMenuItem}}>Select the euro class</MenuItem>
                  <MenuItem value={0} classes={{root: classes.selectMenuItem}}>N/A</MenuItem>
                  {this.state.euro.map((v, key) =>
                    <MenuItem key={key} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={v}>
                      Euro {v}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>

          <GridContainer>
              <GridItem xs={2}>
                <FormLabel className={classes.labelHorizontal}>
                  <strong>Efficiency</strong>
                </FormLabel>
              </GridItem>
            <GridItem xs={4}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="fuel-select" className={classes.selectLabel}>Fuel Type</InputLabel>
                <Select MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        value={environment.fuel === null ? "" : environment.fuel}
                        onChange={this.handleSelect.bind(this)}
                        inputProps={{ name: "fuel", id: "fuel-select" }}
                >
                  <MenuItem disabled classes={{root: classes.selectMenuItem}}>Select the preferred fuel type</MenuItem>
                 {this.state.fuel.map((v, key) =>
                    <MenuItem key={key} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={v}>
                      {v}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </GridItem>
              <GridItem xs={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel htmlFor="efficiency-select" className={classes.selectLabel}>Efficiency Class</InputLabel>
                  <Select MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          value={environment.efficiency === null ? "" : environment.efficiency === "APlus" ? "A+" : environment.efficiency}
                          onChange={this.handleSelect.bind(this)}
                          inputProps={{ name: "efficiency", id: "efficiency-select" }}
                  >
                    <MenuItem disabled classes={{root: classes.selectMenuItem}}>Select the efficiency class</MenuItem>
                    {this.state.efficiency.map((v, key) =>
                      <MenuItem key={key} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={v}>
                        {v}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              {!embedded &&<Button disabled={!modified} color="success" onClick={this.handleUpdate.bind(this)}>Update environment</Button>}
              {!embedded &&<Button disabled={!modified} onClick={this.handleReset.bind(this)}>Reset</Button>}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

Environment.propTypes = {
  vehicle: PropTypes.object,
  classes: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  embedded: PropTypes.bool
}

export default Environment;