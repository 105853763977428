import {ImagesConstants} from "../actions/types";

const initialState = {
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ImagesConstants.IMAGE_ERROR_CLEAR:
      return initialState;
    case ImagesConstants.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        [action.file]: false
        /*[action.vehicle]: {
          [padPKCS7(action.file.name)]: false
        }*/
      };
    case ImagesConstants.UPLOAD_IMAGE_RESPONSE:
      return {
        ...state,
        [action.file]: true,
        /*[action.vehicle]: {
          [padPKCS7(action.file.name)]: true
        },*/
        response: {
          [action.file]: action.payload
        }
      };
    case ImagesConstants.UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        [action.file]: false,
        error: action.error.message
      }
    default:
      return state;
  }
};
