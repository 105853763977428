import { VehicleConstants, PublishingConstants } from "./types";

import {database_request_url, typo3_url} from "../store";

export const beginAddVehicle = () => dispatch => {
  dispatch({type: VehicleConstants.BEGIN_ADD_VEHICLE});
};

export const fetchVehicles = () => dispatch => {
  fetch(database_request_url + "/vehicles/")
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: VehicleConstants.FETCH_VEHICLES,
        payload: data
      });
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};

export const fetchVehicle = id => dispatch => { // TODO verify if for correctness (syntax/UUID).
  //console.log("fetchVehicle Action", id);
  fetch(database_request_url + "/vehicles/" + id)
    .then(response => response.json())
    .then(data => {
      //console.log("fetchVehicle fetched:", data);
      // TODO verify received data for one single object.
      dispatch({
        type: VehicleConstants.FETCH_VEHICLE,
        payload: data
      });
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};

export const fetchVehicleBlocking = (id, callback) => dispatch => {
  //console.log("fetchVehicle Action", id);
  fetch(database_request_url + "/vehicles/" + id)
    .then(response => response.json())
    .then(data => {
      //console.log("fetchVehicle fetched:", data);
      dispatch({
        type: VehicleConstants.FETCH_VEHICLE,
        payload: data
      });
      dispatch(callback(data));
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};

export const fetchBrands = () => dispatch => {
  fetch(database_request_url + "/brands")
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: VehicleConstants.FETCH_BRANDS,
        payload: data
      });
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};

export const fetchLocations = () => dispatch => {
  fetch(typo3_url + "api?type=29101", {mode: 'cors'})
    .then(response => {
      //console.log("got", response);
      if(response.status !== 200) {
        throw new Error('No Address data found');
      }
      return response.json();
    })
    .then(data => {
      dispatch({
        type: VehicleConstants.FETCH_LOCATIONS,
        payload: data.locations
      });
    })
    .catch(err => {
      //console.log("caught it!", err);
    });
};

export const fetchSalesContacts = () => dispatch => {
  fetch(typo3_url + "api?type=29102", {mode: 'cors'})
    .then(response => {
      //console.log("got", response);
      if(response.status !== 200) {
        throw new Error('No Address data found');
      }
      return response.json();
    })
    .then(data => {
      dispatch({
        type: VehicleConstants.FETCH_SALESCONTACTS,
        payload: data
      });
    })
    .catch(err => {
      //console.log("caught it!", err);
    });
};

export const fetchClasses = () => dispatch => {
  fetch(database_request_url + "/classes")
    .then(response => {
      //console.log("response", response);
      return response.json(); })
    .then(data => {
      dispatch({
        type: VehicleConstants.FETCH_CLASSES,
        payload: data
      });
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};

export const fetchModels = (brand) => dispatch => {
  fetch(database_request_url + "/models/" + brand)
    .then(response => { //console.log("response", response);
    return response.json(); })
    .then(data => {
      dispatch({
        type: VehicleConstants.FETCH_MODELS,
        brand: brand,
        payload: data
      });
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};

export const fetchConfigData = () => dispatch => {
  //console.log("fetchConfigData");
  fetch(database_request_url + "/config/")
    .then(response => { //console.log("response", response);
    return response.json(); })
    .then(data => {
      dispatch({
        type: VehicleConstants.FETCH_CONFIG,
        data: data
      });
    })
    .catch(err => {
      console.error("caught it!", err);
    });
};

export const initVehicle = (vehicle) => (dispatch, getState) => {
  //console.log("saveVehicle content", vehicle);
  fetch(database_request_url + "/vehicles/init",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...vehicle
      })
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      dispatch({
        type: VehicleConstants.INIT_VEHICLE,
        payload: data
      });
    })
    .catch(err => {
      //console.log("caught it!", err);
    });
};

export const updateVehicle = vehicle => (dispatch) => {
  console.log("updateVehicle content", vehicle);
  fetch(database_request_url + "vehicles/update",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...vehicle
      })
    })
    .then(response => {
      return response.json().then(json => {
        return {
          data: json,
          status: response.status
        }
      })
    })
    .then((res) => {
      if(res.status === 200) {
        dispatch({
          type: VehicleConstants.UPDATE_VEHICLE,
          payload: res.data
        });
      }
      else {
        dispatch({
          type: VehicleConstants.ERROR_VEHICLE,
          payload: res.data
        });
      }
    })
    .catch(err => {
      //console.log("caught it!", err);
      dispatch({
        type: VehicleConstants.ERROR_VEHICLE,
        payload: err
      });
    });
};

export const updatePublishing = pub => (dispatch, getState) => {
  //console.log("updatePublishing", pub);
  const state = getState();
  fetch(database_request_url + "/publish",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...pub
      })
    })
    .then(response => {
      //console.log("got", response);
      return response.json();
    })
    .then(data => {
      dispatch({
        type: PublishingConstants.UPDATE_PUBLISHING,
        payload: data
      });
      // update vehicle
      dispatch(fetchVehicle(state.data.vehicle.id))
    })
    .catch(err => {
      //console.log("caught it!", err);
    });
}

export const addPublishing = (start, end, activate = false, vehicle = undefined) => (dispatch, getState) => {
  //console.log("addPublishing", start.format())
  const vehicleId = vehicle !== undefined ? vehicle.id : getState().data.vehicle.id;
  fetch(database_request_url + "/publish/" + vehicleId,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        enable: start.format(),
        disable: end !== undefined ? end.format() : null,
        active: activate
      })
    })
    .then(response => {
      //console.log("got", response);
      if(response.status === 201) {
        dispatch(fetchVehicles());          // update all for list view
        dispatch(fetchVehicle(vehicleId));  // update the current
        return response.json(); // this fails?
      }
    })
    .then(data => {
        //console.log("data", data);
        dispatch({
          type: PublishingConstants.ADD_PUBLISHING,
          payload: data
        });
    })
    .catch(err => {
      //console.log("caught it!", err);
    });
}

export const addColor = (vehicle_id, color) => (dispatch) => {
  //console.log("addColor", vehicle_id);
  fetch(database_request_url + "/vehicles/color/" + vehicle_id,
    {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        ...color
      })
    })
    .then(response => {
      //console.log("got", response);
      if(response.status !== 200) {
        //console.log("ERROR", response);
      } else {
        dispatch(fetchVehicle(vehicle_id));
      }
    })
    .catch(err => {
      //console.log("caught it!", err);
    });
}

export const removePublishing = pub => (dispatch, getState) => {
  //console.log("removePublishing", pub);
  const state = getState();
  fetch(database_request_url + "/publish/" + state.data.vehicle.id,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...pub
      })
    })
    .then(response => {
      //console.log("got", response);
      return response.json();
    })
    .then(data => {
      dispatch({
        type: PublishingConstants.DELETE_PUBLISHING,
        payload: data
      });
      // update vehicle
      dispatch(fetchVehicle(state.data.vehicle.id))
    })
    .catch(err => {
      //console.log("caught it!", err);
    });
}

export const deactivateAllPublishing = (vehicle = undefined) => (dispatch, getState) => {
  const vehicleId = vehicle !== undefined ? vehicle.id : getState().data.vehicle.id;
  fetch(database_request_url + "/publish/" + vehicleId,
    {
      method: "DELETE"
    })
    .then(response => response.status)
    .then(status => {
      if(status === 200) {
        dispatch(fetchVehicles());          // update all for list view
        dispatch(fetchVehicle(vehicleId));  // update the current
      } else {
        console.error("Failed", status);
      }
    })
    .catch(err => {
      console.error("caught it!", err);
    });
}

export const assignSalesContact = (contact, vehicle) => (dispatch) => {
  fetch(database_request_url + "/vehicles/contact",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: vehicle.id,
        uid: contact
      })
    })
    .then(response => {
      if(response.status !== 200) {
        //console.log("ERROR", response);
      } else {
        dispatch(fetchVehicle(vehicle.id));
        dispatch(fetchVehicles()); // TODO this is not efficient, reload all for list view
      }
    })
    .catch(err => {
      //console.log("caught it!", err);
    });
}

export const assignLocation = (location, vehicle) => (dispatch) => {
  fetch(database_request_url + "/vehicles/location",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: vehicle.id,
        uid: location
      })
    })
    .then(response => {
      if(response.status !== 200) {
        //console.log("ERROR", response);
      } else {
        dispatch(fetchVehicle(vehicle.id));
        dispatch(fetchVehicles()); // TODO this is not efficient
      }
    })
    .catch(err => {
      //console.log("caught it!", err);
    });
}

export const deleteVehicle = vehicle_id => (dispatch) => {
  //console.log("deleteVehicle", vehicle_id);
  fetch(database_request_url + "/vehicles/" + vehicle_id,
    {
      method: "DELETE",
      headers: {"Content-Type": "application/json"},
    })
    .then(response => {
      //console.log("got", response);
      if(response.status !== 200) {
        //console.log("ERROR", response);
      } else {
        dispatch(fetchVehicles());
      }
    })
    .catch(err => {
      //console.log("caught it!", err);
    });
}

export const updatePrice = (vehicle_id, price) => (dispatch) => {
  //console.log("updatePrice", vehicle_id);
  fetch(database_request_url + "/vehicles/price/" + vehicle_id,
    {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        ...price,
        id: vehicle_id
      })
    })
    .then(response => {
      //console.log("got", response);
      if(response.status !== 200) {
        //console.log("ERROR", response);
      } else {
        dispatch(fetchVehicle(vehicle_id));
      }
    })
    .catch(err => {
      //console.log("caught it!", err);
    });
}

export const assignCheckOptions = (vehicle_id, options) => (dispatch) => {
  fetch(database_request_url + "/vehicles/options?id=" + vehicle_id,
    {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(options)
    })
    .then(response => {
      if(response.status !== 200) {
        //console.log("ERROR", response);
      } else {
        dispatch(fetchVehicle(vehicle_id));
      }
    })
    .catch(err => {
      console.log("caught it!", err);
    });
}

export const hideCheckOption = option => dispatch => {
  console.log("hideCheckOption", option);
  fetch(database_request_url + "/options",
    {
      method: "DELETE",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        ...option,
      })
    })
    .then(response => {
      //console.log("got", response);
      if(response.status !== 200) {
        //console.log("ERROR", response);
      } else {
        dispatch(fetchOptions());
      }
    })
    .catch(err => {
      console.log("caught it!", err);
    });
}

export const addCheckOption = name => dispatch => {
  fetch(database_request_url + "/options",
    {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        nameEn: name,
        type: "C"
      })
    })
    .then(response => {
      //console.log("got", response);
      if(response.status !== 200) {
        //console.log("ERROR", response);
      } else {
        dispatch(fetchOptions());
      }
    })
    .catch(err => {
      console.log("caught it!", err);
    });
}

export const fetchOptions = () => (dispatch) => {
  //console.log("updatePrice", vehicle_id);
  fetch(database_request_url + "options?type=C",
    {
      method: "GET",
    })
    .then(res => res.json())
    //.then(res => { console.log(res); return res; })
    .then(data =>
      dispatch({
        type: VehicleConstants.FETCH_OPTIONS,
        payload: data
      }))
    .catch(err => {
      console.log("caught it!", err);
    });
}

export const fetchFeatures = () => (dispatch, getState) => {
  const vehicle = getState().data.vehicle;
  const url = database_request_url + "features" + (vehicle !== undefined ? "?brand=" + vehicle.brand.id : "" );
  //console.log("updatePrice", vehicle_id);
  fetch(url,
    {
      method: "GET",
    })
    .then(res => res.json())
    //.then(res => { console.log(res); return res; })
    .then(data =>
      dispatch({
        type: VehicleConstants.FETCH_FEATURES,
        payload: data
      }))
    .catch(err => {
      console.log("caught it!", err);
    });
}

export const addFeature = (code, name) => (dispatch, getState) => {
  const vehicle = getState().data.vehicle;
  fetch(database_request_url + "/features",
    {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        code: code,
        name: name,
        brand: vehicle.brand
      })
    })
    .then(response => {
      //console.log("got", response);
      if(response.status !== 200) {
        //console.log("ERROR", response);
      } else {
        dispatch(fetchFeatures());
      }
    })
    .catch(err => {
      console.log("caught it!", err);
    });
}

export const assignFeatures = (vehicle_id, features) => (dispatch) => {
  fetch(database_request_url + "/vehicles/features?id=" + vehicle_id,
    {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(features)
    })
    .then(response => {
      if(response.status !== 200) {
        //console.log("ERROR", response);
      } else {
        dispatch(fetchVehicle(vehicle_id));
      }
    })
    .catch(err => {
      console.log("caught it!", err);
    });
}
/*
export const saveOffer = () => (dispatch, getState) => {
  const state = getState();
  //console.log("saveOffer content", state);

  const { model, cart } = state.config;
  //let config = (state.cart !== undefined && state.cart.config !== undefined) ? state.cart.config : false;
  if(model === undefined)
    return;

  fetch(database_request_url + "/data/save",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        version: state.config.status !== undefined ? state.config.status : 1,
        modelId: model.id,
        model: model.B06_Artikelnummer,
        color: state.config.color,
        cart, // TODO reduce only to id of items
        offer: state.config.offer,
        sizes: state.config.sizes,
        dealer: state.config.dealer !== undefined ? state.config.dealer : 0,
        financing: state.config.finance_selected,
        insurance: state.config.insurance_selected,
        customer: state.config.customer !== undefined ? state.config.customer : null
      })
    })
    .then(response => {
      //console.log("got", response);
      return response.json();
    })
    .then(data => {
      //console.log("Offer Hash", data);
      dispatch({
        type: SAVE_OFFER,
        payload: data
      });
    })
    .catch(err => {
      //console.log("caught it!", err);
    });
};
*/
