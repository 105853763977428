import React from "react";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { DirectionsCar as IconCar} from "@material-ui/icons";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle";
import {makeStyles} from "@material-ui/core/styles";
import CardIcon from "../Card/CardIcon";
import CustomInput from "../CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import {database_request_url} from "../../store";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Verification from "./Steps/Verification";
import Spinner from "../Spinner";
import Button from "../CustomButtons/Button";

const useStyles = makeStyles(styles);
function VehicleBasicCard(props) {
  const [brand, setBrand] = React.useState("");
  const [modelText, setModelText] = React.useState("");
  const [modelSelect, setModelSelect] = React.useState("");
  const [vin, setVin] = React.useState("");

  const [vinOK, setVinOK] = React.useState(false);
  const [brandOK, setBrandOK] = React.useState(false);
  const [modelOK, setModelOK] = React.useState(false);
  const [ok, setOk] = React.useState(false);

  const [brands, setBrands] = React.useState(undefined);
  const [models, setModels] = React.useState(undefined);
  const isEdit = props.vehicle !== undefined;

  async function fetchBrands() {
    const res = await fetch(database_request_url + "brands");
    res.json()
      //.then(res => { console.log(res); return res; })
      .then(res => setBrands(res))
      .catch(err => console.log(err));
  }

  async function fetchModels(brand) {
    const res = await fetch(database_request_url + "models/" + brand);
    res.json()
      //.then(res => { console.log(res); return res; })
      .then(res => setModels(res.values))
      .catch(err => console.log(err));
  }

  React.useEffect(() => {
      fetchBrands();
  }, []); // [] dependencies to fetch the data only once.

  React.useEffect(() => {
    brand && fetchModels(brand.id);
  }, [brand]); // [] dependencies to fetch the data only once.

  React.useEffect(() => {
    if(props.vehicle) {
      handleChangeVin(props.vehicle.vin);
      handleModelText(props.vehicle.modelText);
      brands && handleBrand(brands.find(b => b.id === props.vehicle.brand.id));
      models && handleModelSelect(models.find(m => m.name === props.vehicle.model));
    }
  },[props.vehicle, brands, models]);

  const handleBrand = value => {
    setBrand(value);
    setBrandOK(true);
  }

  const handleModelSelect = value => {
    setModelSelect(value);
    //console.log("setting model text", modelText);
    !isEdit && handleModelText(value.name);
    setModelOK(true);
  }

  const handleModelText = value => {
    setModelText(value);
    setModelOK(modelSelect || Verification.verifyLength(value, 1));
  }

  const handleChangeVin = value => {
    setVin(value);
    setVinOK(Verification.verifyLength(value, 3));
  }

  const handleUpdate = () => {
    props.finishFunc( {
        ...props.vehicle,
        model: modelSelect.name,
        modelText: modelText
    })
  }

  const handleReset = () => {
    handleModelText(props.vehicle.modelText);
    models && handleModelSelect(models.find(m => m.name === props.vehicle.model));
  }

  /**
   * Validate
   */
  React.useEffect(() => {
    setOk(vinOK && brandOK && modelOK);
  }, [vinOK, brandOK, modelOK]);

  /**
   * Update data to parent
   */
  React.useEffect(() => {
    //console.log("Changes", models);
    //ok && console.log("Update with data: ", modelSelect);
    ok && !isEdit && props.finishFunc({
      vin: vin,
      brand: brand,
      modelText: modelText,
      model: modelSelect.name
    })
  }, [ok, vin, modelText, modelSelect]);

  const classes = useStyles();

  if(brands === undefined) {
    return <Spinner/>;
  }

  const {embedded} = props;
  const modified = isEdit && (props.vehicle.model !== modelSelect.name || props.vehicle.modelText !== modelText);

  return (
    <Card plain={embedded}>
      <CardHeader color={ok ? "success" : "warning"} icon>
        <CardIcon color={ok ? "success" : "warning"}>
          <IconCar />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Vehicle Data</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <CustomInput
              success={vinOK}
              error={vin !== "" && !vinOK }
              labelText={<span>VIN <small>(required)</small></span>}
              id="vin"
              formControlProps={{fullWidth: true}}
              inputProps={{
                value:vin,
                disabled:isEdit,
                onChange: event => handleChangeVin(event.target.value),
                endAdornment: (
                  <InputAdornment position="end" className={classes.inputAdornment}>
                    <IconCar className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )}}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="brand-select" className={classes.selectLabel}>Brand</InputLabel>
              <Select MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      value={brand}
                      disabled={isEdit}
                      onChange={event => handleBrand(event.target.value)}
                      inputProps={{ name: "brandSelect", id: "brand-select", value:brand }}
              >
                <MenuItem disabled classes={{root: classes.selectMenuItem}}>Choose the brand</MenuItem>
                {brands.map((v, key) =>
                  <MenuItem key={key} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={v}>
                    {v.name}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="model-select" className={classes.selectLabel}>Modelfamily</InputLabel>
              <Select MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      value={modelSelect}
                      //disabled={isEdit}
                      onChange={event => handleModelSelect(event.target.value)}
                      inputProps={{ name: "modelSelect", id: "model-select" }}
              >
                <MenuItem disabled classes={{root: classes.selectMenuItem}}>
                  { brand ? "Choose the modelfamily that suits best." : "Please select a brand first."}
                </MenuItem>
                {models && models.map((v, key) =>
                  <MenuItem key={key} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={v}>
                    {v.name}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              //success={this.state.modelState}
              //error={this.state.model !== "" && !this.state.modelState}
              labelText={<span>Full Model Name<small>(The model family selection is used for filtering)</small></span>}
              id="model"
              formControlProps={{fullWidth: true}}
              inputProps={{
                onChange: event => handleModelText(event.target.value),
                value: modelText
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            {!embedded &&<Button disabled={!modified} color="success" onClick={handleUpdate.bind(this)}>Update</Button>}
            {!embedded &&<Button disabled={!modified} onClick={handleReset.bind(this)}>Reset</Button>}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );

}

VehicleBasicCard.propTypes = {
  finishFunc: PropTypes.func.isRequired,
  vehicle: PropTypes.object, // optional for editing
  embedded: PropTypes.bool
}

export default VehicleBasicCard;