import React from "react";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "../CustomInput/CustomInput";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardIcon from "../Card/CardIcon";
import {DirectionsCar as IconCar} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Spinner from "../Spinner";
import {database_request_url} from "../../store";
import Button from "../CustomButtons/Button";

class VehicleDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicle: (props.vehicle && props.vehicle) || {
        milage: 0,
        weight: 0,
        modelYear: 2020,
        bodyStyle: "",
        condition: "",
        used: "",
      },
      conditions: ["N/A", "sehr gut", "gut", "normal", "ausreichend", "mangelhaft", "ungenügend"], // TODO fetch from Server
      used: [
        {key: 1, value: "New"},
        {key: 2, value: "Used"},
        {key: 3, value: "Employee's car"},
        {key: 4, value: "Olt-timer"},
        {key: 5, value: "Demonstration car"},
        {key: 6, value: "One-Day registration"},
        //{key: 7, value: "N/A"},
        {key: 8, value: "Company car"}],
      modified: false,
      isEdit: props.vehicle !== undefined
    }
    this.fetchBodyStyles();
  }

  async fetchBodyStyles() {
    fetch(database_request_url + "bodystyles")
      .then(res => res.json())
      .then(res => this.setState({
        ...this.state,
        bodyStyles: res
      }))
      .catch(err => console.log(err));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.vehicle !== this.props.vehicle && this.props.vehicle) {
      this.handleReset();
    }

    if(!this.state.isEdit && prevState.vehicle !== this.state.vehicle) {
      this.handleUpdate();
    }

    if(this.state.isEdit && prevState.vehicle !== this.state.vehicle && this.state.vehicle !== this.props.vehicle) {
       this.setState({
         modified: true
       });
    }
  }

  handleUpdate() {
    this.props.update(this.state.vehicle);
  }

  handleReset() {
    this.setState({
      ...this.state,
      vehicle: this.props.vehicle,
      modified: false
    });
  }

  // https://upmostly.com/tutorials/react-onchange-events-with-examples
  handleChange(e) {
    this.setState({
      ...this.state,
      vehicle: {
        ...this.state.vehicle,
        [e.target.id]:e.target.value
      }
    });
  }

  handleValidateNumber(e) {
    this.setState({
      ...this.state,
      vehicle: {
        ...this.state.vehicle,
        [e.target.id]: Number(e.target.value)
      }
    });
  }

  handleToggle(e) {
    this.setState({
      ...this.state,
      vehicle: {
        ...this.state.vehicle,
        [e.target.id]: !this.state.vehicle[e.target.id]
      }
    })
  }

  handleSelect(e) {
    //console.log("handleSelect", e.target.value);
    this.setState({
      ...this.state,
      vehicle: {
        ...this.state.vehicle,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSave() {
    this.props.update( {
      ...this.props.vehicle,
      milage: this.state.milage,
      weight: this.state.weight,
      modelYear: this.state.modelYear,
      bodyStyle: this.state.bodyStyle,
      condition: this.state.condition,
      used: this.state.used,
    })
  }

  render() {
    const { classes, embedded } = this.props;
    const { vehicle, bodyStyles, modified } = this.state;
    if(this.props.vehicle === undefined || vehicle === undefined)
      return <Spinner/>;

    //console.log("Details", vehicle);

    return (
      <Card plain={embedded}>
        <CardHeader color={"primary"} icon>
          <CardIcon color={"primary"}>
            <IconCar />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Vehicle Data Details</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={2}>
              <FormLabel className={classes.labelHorizontal}>
                <strong>Model</strong>
              </FormLabel>
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                id="milage"
                error={vehicle.milage < 0}
                labelText={<span>Milage <small>(in km)</small></span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: vehicle.milage,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this),
                }}
              />
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                id="weight"
                error={vehicle.weight < 0}
                labelText={<span>Weight <small>(in kg)</small></span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: vehicle.weight,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={2}>
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                id="modelYear"
                error={vehicle.modelYear < 0}
                labelText={<span>Model Year</span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: vehicle.modelYear,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this),
                }}
              />
            </GridItem>
            <GridItem xs={4}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="bodyStyle-select" className={classes.selectLabel}>Body Style</InputLabel>
                <Select MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        value={vehicle.bodyStyle === 0 ? "" : vehicle.bodyStyle}
                        onChange={this.handleSelect.bind(this)}
                        inputProps={{ name: "bodyStyle", id: "bodyStyle-select" }}
                >
                  <MenuItem disabled classes={{root: classes.selectMenuItem}}>Select the body style</MenuItem>
                  <MenuItem key={0} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={0}>
                    N/A
                  </MenuItem>
                  {bodyStyles && bodyStyles.map((v, key) =>
                    <MenuItem key={key} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={v.id}>
                      {v.nameEn}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={2}></GridItem>
            <GridItem xs={4}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="used-select" className={classes.selectLabel}>Used or new</InputLabel>
                <Select MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        value={vehicle.used === 0 ? "" : vehicle.used}
                        onChange={this.handleSelect.bind(this)}
                        inputProps={{ name: "used", id: "used-select" }}
                >
                  <MenuItem disabled classes={{root: classes.selectMenuItem}}>Select a state</MenuItem>
                  <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={0}>
                    N/A
                  </MenuItem>
                  {this.state.used.map((v, key) =>
                    <MenuItem key={key} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={v.key}>
                      {v.value}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    id="used"
                    checked={vehicle.used > 1}
                    tabIndex={-1}
                    disabled
                    onClick={this.handleToggle.bind(this)}
                    checkedIcon={
                      <Check className={classes.checkedIcon} />
                    }
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="is Used"
              />
            </GridItem>
            <GridItem xs={4}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="condition-select" className={classes.selectLabel}>Condition</InputLabel>
                <Select MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        value={vehicle.condition === 0 ? "" : vehicle.condition}
                        onChange={this.handleSelect.bind(this)}
                        inputProps={{ name: "condition", id: "condition-select" }}
                >
                  <MenuItem disabled classes={{root: classes.selectMenuItem}}>Select a condition</MenuItem>
                  {this.state.conditions.map((v, key) =>
                    <MenuItem key={key} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={key}>
                      {v}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12}>
              {!embedded &&<Button disabled={!modified} color="success" onClick={this.handleUpdate.bind(this)}>Update</Button>}
              {!embedded &&<Button disabled={!modified} onClick={this.handleReset.bind(this)}>Reset</Button>}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

VehicleDetails.propTypes = {
  vehicle: PropTypes.object,
  classes: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  embedded: PropTypes.bool
}

export default VehicleDetails;