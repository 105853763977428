import React from "react";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {Step} from "../../Wizard/Wizard";
import BodyColorCard from "../BodyColorCard";
import VehicleBasicCard from "../VehicleBasicCard";
import Pricing from "../Pricing";

class Step1 extends Step {
  constructor(props) {
    super(props);
    this.state = {
      vehicle: false,
      color: false,
      price: false,
      completed: false
    };
  }

  sendState() {
    if(!this.isValidated())
      return;
    console.log("sendState", this.state);
    if(!this.props.vehicle) {
      const vehicle = {
        vin: this.state.vehicle.vin,
        brand: this.state.vehicle.brand.id,
        model: this.state.vehicle.model,
        modelText: this.state.vehicle.modelText,
        price: this.state.price,
        color: this.state.color
      }
      console.log("Init Vehicle with", vehicle);
      this.props.initVehicle(vehicle);
    } else {
      console.log("TODO UPDATE");
    }
    return this.state;
  }

  isValidated() {
    // check numbers
    const { price } = this.state;
    return price.gross > 0
      && !isNaN(price.net)
      && !isNaN(price.foreignGross)
      && !isNaN(price.foreignNet)
      && price.vat > 0
      && price.nova > -1
      && this.state.completed;
  }

  setVehicle = vehicle => {
    console.log("setVehicle", vehicle);
    this.setState({
      ...this.state,
      vehicle: vehicle
    });
  }

  setColor = color => {
    this.setState({
      ...this.state,
      color: color
    })
  }

  setPrice = (ignoreThisParameter, price) => {
    this.setState({
      ...this.state,
      price: price
    })
  }

  /**
   * check for valid data
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.vehicle !== this.state.vehicle || prevState.color !== this.state.color || prevState.price !== this.state.price) {
      this.setState({
        ...this.state,
        completed: this.state.vehicle && this.state.color && this.state.price
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Let{"'"}s start with the basic vehicle data
          </h4>
        </GridItem>
        {/*<SalesContactCard classes={classes} update={assignSalesContact} />*/}
        <VehicleBasicCard embedded finishFunc={this.setVehicle.bind(this)} />
        <BodyColorCard embedded finishFunc={this.setColor.bind(this)}/>
        <Pricing classes={classes} embedded updatePrice={this.setPrice.bind(this)} />
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object,
  vehicle: PropTypes.object
};

export default withStyles(styles)(Step1);
