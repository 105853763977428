/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";
import {typo3_url, version} from "../../store";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  });
  const user = JSON.parse(localStorage.getItem('user'));
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href={typo3_url + "/typo3"} className={block}>Backend</a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href={typo3_url} className={block}>Frontend (Website)</a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://git.rennviech.com/exclusive-cars/website-extension/-/wikis/Manual" className={block}>Support</a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          &copy; {1900 + new Date().getYear()}{" "}
          <a
            href="https://www.rennviech.com"
            className={anchor}
            target="_blank"
          >Rennviech e.U.
          </a>
          , Version {version}<br/><small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.
          {user && user.username + " " + user.session}</small>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};
