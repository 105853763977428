import React from "react";
// @material-ui/core components

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/editStyle";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/styles';
import Spinner from "../../components/Spinner";
import { connect } from "react-redux";
import {fetchVehicle, updatePrice, updateVehicle, addColor, assignCheckOptions, assignFeatures} from "../../actions/vehiclesAction";
import {ccyFormat, co2Format, consumptionFormatCombined, powerFormat} from "../../Utils";
import Publishing from "../../components/Vehicles/Publishing";
import ImageViewer from "../../components/Vehicles/ImageViewer";
import Pricing from "../../components/Vehicles/Pricing";
import VehicleBasicCard from "../../components/Vehicles/VehicleBasicCard";
import BodyColorCard from "../../components/Vehicles/BodyColorCard";
import SalesContactCard from "../../components/Vehicles/SalesContactCard";
import LocationCard from "../../components/Vehicles/LocationCard";
import VehicleDetails from "../../components/Vehicles/VehicleDetails";
import Environment from "../../components/Vehicles/Environment";
import DriveTrain from "../../components/Vehicles/DriveTrain";
import OptionsList from "../../components/Vehicles/OptionsList";
import FeaturesList from "../../components/Vehicles/FeaturesList";
//import Description from "../../components/Vehicles/Description";

class EditVehicle extends React.Component {
  constructor(props) {
    super(props);
    const { vehicle, hash } = this.props.location;
    // this page was direclty called (by link), load data by hash
    const id = (vehicle === undefined) ? hash.replace('#','') : vehicle.id;
    // fetch or reload vehicle
    if(this.props.vehicle === undefined || this.props.vehicle.id !== id) {
      this.props.fetchVehicle(id)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.vehicle !== prevProps.vehicle && this.props.vehicle) {
      this.setState({
        ...this.state,
        vehicle: this.props.vehicle
      })
    }
  }

  saveModification() {
    this.props.updateVehicle(this.state.vehicle);
  }

  /**
   * Only the model name is allowed to change
   * @param data
   */
  updateVehicleBasicData = data => {
    if(data) {
      this.setState({
        ...this.state,
        vehicle: {
          ...this.state.vehicle,
          model: data.model,
          modelText: data.modelText
        }
      });
    }
  }

  updateVehicleColor = data => {
    if(data) {
      this.setState({
        ...this.state,
        vehicle: {
          ...this.state.vehicle,
          color: data
        }
      });
    }
  }

  updateColor = color => {
    this.setState({
      ...this.state,
      color: color
    })
  }

  updateEnviromentalData = data => {
    this.props.updateVehicle({
      ...this.props.vehicle,
      environment: data
    });
  }

  updateDriveTrain = data => {
    this.props.updateVehicle({
      ...this.props.vehicle,
      driveTrain: data
    });
  }

  updateOptions = data => {
    this.props.assignCheckOptions(this.props.vehicle.id, data);
  }

  updateFeatures = data => {
    this.props.assignFeatures(this.props.vehicle.id, data);
  }

  updateDescription = data => {

  }

  render() {
    const { vehicle, classes } = this.props;
    console.log("props", this.props);
    console.log("state", this.state);
    if(!vehicle)
      return <Spinner/>
    else
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary" text>
                <CardText color="primary">
                  <h4 className={classes.cardTitleWhite}>{vehicle.brand.name}</h4>
                  <h4 className={classes.cardCategoryWhite}>{vehicle.model}</h4>
                </CardText>
                <h4 className={classes.cardIconTitle}>Overview</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={4}>
                    <Table
                      hover
                      tableHeaderColor="primary"
                      tableData={[
                        ["VIN", vehicle.vin],
                        ["Color", vehicle.colors && vehicle.colors.length > 0 && vehicle.colors[0].name],
                        ["Power", powerFormat(vehicle.driveTrain.power)],
                        ["Price (Gross)", ccyFormat(vehicle.price.gross)]
                      ]}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <Table
                      hover
                      tableHeaderColor="primary"
                      tableData={[
                        ["Gearbox", vehicle.driveTrain.gearbox],
                        ["Interior Color", vehicle.interiorColor],
                        ["CO2", co2Format(vehicle.environment.co2)],
                        ["Consumption (I,O,C)", consumptionFormatCombined(vehicle.environment.inside, vehicle.environment.outside, vehicle.environment.combined)]
                      ]}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {/*<Description classes={classes} vehicle={vehicle} update={this.updateDescription.bind(this)} />*/}
            <ImageViewer classes={classes} vehicle={vehicle} />
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <VehicleBasicCard classes={classes} vehicle={vehicle} finishFunc={this.props.updateVehicle} />
            <VehicleDetails classes={classes} vehicle={vehicle} update={this.props.updateVehicle}/>
            <Pricing classes={classes} vehicle={vehicle} updatePrice={this.props.updatePrice}/>
            <BodyColorCard classes={classes} vehicle={vehicle} finishFunc={this.updateVehicleColor.bind(this)}/>
            <DriveTrain classes={classes} vehicle={vehicle} update={this.updateDriveTrain.bind(this)} />
            <Environment classes={classes} vehicle={vehicle} update={this.updateEnviromentalData.bind(this)} />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <SalesContactCard classes={classes} selected={[vehicle]} />
            <LocationCard classes={classes} selected={[vehicle]} />
            <Publishing classes={classes} vehicle={vehicle}/>
          </GridItem>
          <GridItem xs={12}>
            <OptionsList classes={classes} vehicle={vehicle} update={this.updateOptions.bind(this)} />
            <FeaturesList classes={classes} vehicle={vehicle} update={this.updateFeatures.bind(this)} />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

EditVehicle.protoTypes = {
  classes: PropTypes.object.isRequired,
  vehicle: PropTypes.object.isRequired,
  updatePrice: PropTypes.func.isRequired,
  addColor: PropTypes.func.isRequired,
  assignCheckOptions: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return (
    {
      vehicle: state.data.vehicle
    });
}

export default connect(mapStateToProps,{ fetchVehicle, updatePrice, updateVehicle, addColor, assignCheckOptions, assignFeatures })(withStyles(styles)(EditVehicle));

