import React from "react";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Button from "../CustomButtons/Button";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import CardIcon from "../Card/CardIcon";
import {ContactPhone} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Spinner from "../Spinner";
import {connect} from "react-redux";
import {assignSalesContact, fetchSalesContacts} from "../../actions/vehiclesAction";

class SalesContactCard extends React.Component {
  constructor(props) {
    super(props);
    const { selected } = props;
    props.fetchSalesContacts();
    this.state = {
      salesContact: selected && selected.length > 0 ? selected[0].contact : ""
    }
  }

  handleSelectContact = event => {
    this.setState({ salesContact: event.target.value});
  }

  assignSalesContact() {
    this.props.selected.map(vehicle => this.props.assignSalesContact(this.state.salesContact, vehicle));
  }

  render() {
    const { classes, embedded, selected, salesContacts } = this.props;

    if(salesContacts === undefined)
      return <Spinner/>;

    // when a salescontact is assigned to a given vehicle:
    const { salesContact } = this.state;
    //console.log("salesContact", salesContact);
    const modified = selected.length === 1 ? salesContact !== selected[0].contact : true;
    //console.log("modified", modified);

    return (
      <Card plain={embedded} className={classes.fullHeightCard}>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <ContactPhone/>
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Sales Contact</h4>
        </CardHeader>
        <CardBody>
          <p>Select the sales contact to assgin to the selected vehicles.</p>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="salescontact-select" className={classes.selectLabel}>
              Choose Sales Contact
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={salesContact > 0 ? salesContact : ""}
              onChange={this.handleSelectContact.bind(this)}
              inputProps={{
                name: "salescontact",
                id: "salescontact-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Choose Sales Contact
              </MenuItem>
              {salesContacts && salesContacts.map(c =>
                <MenuItem key={c.uid}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={c.uid}
                >
                  {c.name} ({c.location.title})
                </MenuItem>
              )}
            </Select>
          </FormControl>

          <Button color="success"
                  disabled={selected.length === 0 || salesContact === "" || !modified}
                  onClick={this.assignSalesContact.bind(this)}>
            Assign {selected.length > 0 && <strong>&nbsp;to {selected.length} Vehicles</strong>}
          </Button>

        </CardBody>
      </Card>
    );
  }
}

SalesContactCard.propTypes = {
  vehicle: PropTypes.object,
  classes: PropTypes.object.isRequired,
  embedded: PropTypes.bool,
  assignSalesContact: PropTypes.func.isRequired,
  fetchSalesContacts: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired
}

function mapStateToProps(state) {
  return (
    {
      salesContacts: state.data.salesContacts
    });
}

export default connect(
  mapStateToProps,{
    fetchSalesContacts,
    assignSalesContact
  })(SalesContactCard);
