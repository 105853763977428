import {ImagesConstants} from "./types";

import {database_request_url} from "../store";
import {fetchVehicle} from "./vehiclesAction";

export const clearError = () => dispatch => {
  dispatch({
    type: ImagesConstants.IMAGE_ERROR_CLEAR
  })
}

export const uploadImage = (key, file, onSuccess, onError) => (dispatch, getState) => {
  console.log("uploadImage", file);
  const vehicle_id = getState().data.vehicle.id;
  const formData = new FormData();
  formData.append('file', file);

  dispatch({
    type: ImagesConstants.UPLOAD_IMAGE_REQUEST,
    file: key
  })

  fetch(database_request_url + "/upload/" + vehicle_id,
    {
      //mode: 'no-cors',
      method: "POST",
      body: formData
    })
    .then(response => {
      //console.log("got", response);
      //console.log("Status code", response.status);
      if(response.status !== 201) {
        throw new Error('File could not be uploaded.');
      }
      return response.json();
    })
    .then(data => {
      dispatch({
        type: ImagesConstants.UPLOAD_IMAGE_RESPONSE,
        file: key,
        payload: data
      });
      onSuccess(key);
      // update vehicle
      dispatch(fetchVehicle(vehicle_id))
    })
    .catch(err => {
      dispatch({
        type: ImagesConstants.UPLOAD_IMAGE_ERROR,
        file: key,
        error: err
      });
      onError(key);
      //console.log("caught it!", err);
    });
}

export const removeImage = image => (dispatch, getState) => {
  console.log("removeImage", image);
  const vehicle_id = getState().data.vehicle.id;
  fetch(database_request_url + "upload/" + vehicle_id,
    {
      //mode: 'no-cors',
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...image
      })
    })
    .then(response => response.status)
    .then(status => {
      console.log("Status code", status);
      if(status === 200) {
        dispatch(fetchVehicle(vehicle_id));  // update the current
      } else {
        console.error("Failed", status);
      }
    })
    .catch(err => {
      console.error("caught it!", err);
    });
}

export const updateImageOrder = images => (dispatch, getState) => {
  console.log("updateImageOrder", images);
  const vehicle_id = getState().data.vehicle.id;
  fetch(database_request_url + "upload/" + vehicle_id,
    {
      //mode: 'no-cors',
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify([
        ...images
      ])
    })
    .then(response => response.status)
    .then(status => {
      console.log("Status code", status);
      if(status === 200) {
        dispatch(fetchVehicle(vehicle_id));  // update the current
      } else {
        console.error("Failed", status);
      }
    })
    .catch(err => {
      console.error("caught it!", err);
    });
}
