import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle";
import SalesContactCard from "../SalesContactCard";
import LocationCard from "../LocationCard";
import Spinner from "../../Spinner";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...styles
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  sendState() {
    return this.state;
  }

  isValidated() {
    return true;
  }

  render() {
    const { classes, vehicle } = this.props;

    if(vehicle === undefined)
      return <Spinner/>

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>Please proceed to list view to assign a sales contact and publish the vehicle.</h4>
          <h6>More functionalities will be added soon.</h6>
        </GridItem>
        <GridItem xs={12}>
          <SalesContactCard embedded classes={classes} selected={[vehicle]} />
        </GridItem>
        <GridItem xs={12}>
          <LocationCard embedded classes={classes} selected={[vehicle]} />
        </GridItem>
      </GridContainer>
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object,
  vehicle: PropTypes.object
};

export default withStyles(style)(Step3);
