import {applyMiddleware, compose, createStore} from "redux";
import { createLogger } from 'redux-logger';
import rootReducer from "./reducers";
import thunk from 'redux-thunk';
// FIXME this may cause problems on the live server and browsers without the redux extension

console.log("Using Environemnt: ", process.env);

const devTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() ;

// reference https://www.youtube.com/watch?v=93p3LxR9xfM
// compose and enhancer is only needed for the chrome extension

export const base_url = process.env.REACT_APP_PUBLIC_URL !== undefined ? process.env.REACT_APP_PUBLIC_URL : "http://172.16.12.32:3000/";
export const database_request_url = process.env.REACT_APP_DB_URL !== undefined ? process.env.REACT_APP_DB_URL : "http://localhost:8086/";
export const image_url = process.env.REACT_APP_IMAGE_URL !== undefined ? process.env.REACT_APP_IMAGE_URL : "https://images.rennviech.com/";
export const typo3_url = process.env.REACT_APP_TYPO3_URL !== undefined ? process.env.REACT_APP_TYPO3_URL : "http://exclusivecars.local/";
export const auth_url = process.env.REACT_APP_AUTH_URL !== undefined ? process.env.REACT_APP_AUTH_URL : "http://localhost:8086/fake";
export const mobile_url = process.env.MOBILE_URL !== undefined ? process.env.MOBILE_URL : "https://services.mobile.de";

export const version = "1.18.2";

const initialState = {};
// Redux: Store
export const store = createStore(
  rootReducer,
  initialState,
  devTools ?
   compose(applyMiddleware(createLogger(), thunk), devTools):    // development
   applyMiddleware(thunk)                    // production
);
