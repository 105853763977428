import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import padPKCS7 from 'crypto-js/sha256';

// core components
import Button from "components/CustomButtons/Button.js";

import {connect} from "react-redux";
import {uploadImage, clearError} from "../../actions/imagesAction";
import {useDropzone} from "react-dropzone";
import Spinner from "../Spinner";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import {AddPhotoAlternate as IconDrop, AddToPhotos as IconDrag, Delete} from "@material-ui/icons";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";

const StatusTypes = {
  none: 0,
  uploading: 99,
  done: 1,
  error: -1
};

const Dropzone = ({ onDrop, accept }) => {
  // Initializing useDropzone hooks with options
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept
  });
  /*
      useDropzone hooks exposes two functions called getRootProps and getInputProps
      and also exposes isDragActive boolean
    */

  return (
    <div {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (<>
            <IconDrop/><br/>
            <p className="dropzone-content">Release to drop the files here</p>
          </>
        ) : (
          <p className="dropzone-content"><>
              <IconDrag/><br/>
              Drag 'n' drop some files here,<br/> or click to select files.<br/><br/>
            <Button>Add images</Button>
            </>
          </p>
        )}
      </div>
    </div>
  );
};


function ImageUpload(props) {
  const [status, setStatus] = React.useState(StatusTypes.none)
  const [images, setImages] = React.useState([]);
  const [processed, setProcessed] = React.useState(0); // used because object changes do not trigger useEffect

  const clear = () => {
    props.clearError();
    setStatus(StatusTypes.none);
  }

  const onDrop =  React.useCallback(acceptedFiles => {
    // Loop through accepted files
    acceptedFiles.map(file => {
      // Initialize FileReader browser API
      const reader = new FileReader();
      // onload callback gets called after the reader reads the file data
      reader.onload = function(e) {
        //console.log("reader", e);
        //console.log("reader f", file);
        const key = padPKCS7(file.name); // just used as array key
        const cuid = key.toString();
        // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it.
        setImages(prevState => [
          ...prevState,
          { id: cuid,
            src: e.target.result,
            file: file,
            loaded: e.loaded,
            state: StatusTypes.none
          }
        ]);
      };
      // Read the file as Data URL (since we accept only images)
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const onUpload = (id) => {
    const index = images.findIndex(f => f.id === id);
    let state = images;
    state[index].state = StatusTypes.done;
    setImages(state);
    //console.log("onUpload index", index);
    //console.log("onUpload state", state);
    //console.log("onUpload images", images);
    //console.log("onUpload satus", status);
    setProcessed(prevState => prevState + 1);
  };

  const onError = (id) => {
    const index = images.findIndex(f => f.id === id);
    let state = images;
    state[index].state = StatusTypes.error;
    setImages(state);
    //console.log("onError index", index);
    //console.log("onError state", state);
    //console.log("onError images", images);
    //console.log("onError status", status);
    setProcessed( prevState => prevState + 1);
  }

  React.useEffect(() => {
    //console.log("processed", processed);
    // clean up after processing:
    if(processed === images.length) {
      setStatus(StatusTypes.done);
      //console.log("Uploading finished.");
    }

    if(status === StatusTypes.uploading && images.filter(f => f.state !== StatusTypes.none).length === 0) {

    }
    //console.log("images updated", images);
  }, [processed])

  /*React.useEffect(() => {
    //document.title = `You clicked ${count} times`;
    if(props.images[key] && status === StatusTypes.uploading) {
      setStatus(StatusTypes.done);
      props.images.error = undefined;
      console.log("UPLOAD FINISHED");
    }
    if(props.images.error && !props.images[key]) {
      setStatus(StatusTypes.error);
      setTimeout(() => clear, 2000);
    }
  }, [key, status, props.images]);
*/
  React.useEffect(() => {
    if(status === StatusTypes.done) {
      const timer = setTimeout(() => {
        //console.log('This will run after 1 second!')
        setStatus(StatusTypes.none);
        setProcessed(0);
        setImages(prevState => prevState.filter(i => i.state !== StatusTypes.done));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [status]);

  const handleSubmit = (e) => {
    setStatus(StatusTypes.uploading);
    images.map(i => props.uploadImage(i.id, i.file, onUpload, onError));
  };

  const handleRemove = (id) => {
    setImages(images.filter(i => i.id !== id));
  };

  let { classes, embedded, addButtonProps } = props;

  const getButtonText = () => {
    switch(status) {
      case StatusTypes.uploading: return <Spinner/>;
      case StatusTypes.done: return "Done.";
      default:
        return `Upload ${images.length} images`;
    }
  }

  //console.log("ImageUpload props", props);
  //console.log("ImageUpload status", status);
  //console.log("ImageUpload Images", images);

  return (
    <Card profile plain={embedded}>
      <GridContainer>
        <GridItem xs={12} sm={4}>
          <CardBody profile>
            <h6 className={classes.cardCategory}>Image Upload</h6>
            <div className={classNames(classes.fileInput, "fileinput")}>
              <Dropzone onDrop={onDrop} accept={"image/*"} />
              {images.length > 0 &&
                <Button {...addButtonProps} onClick={() => handleSubmit()} color={status === StatusTypes.uploading ? "warning" : "primary" }>
                 {getButtonText()}
                </Button>}
            </div>
          </CardBody>
        </GridItem>
        <GridItem xs={12} sm={8}>
          <table className={classes.imageList}>
            <tbody>
            { images.map(f => (
              <tr key={f.id}>
                <td><img src={f.src} width={100}/></td>
                <td>{f.file.name}</td>
                <td>{f.file.size} byte</td>
                <td>
                  <Tooltip id="tooltip-top" title="Remove" placement="bottom" classes={{ tooltip: classes.tooltip }}>
                    <Button color="danger" simple justIcon onClick={() => handleRemove(f.id)} disabled={status !== StatusTypes.none}>
                      <Delete className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  {f.state === -1 ? "Error uploading file" : f.state === 1 ? "OK" : ""}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </GridItem>
      </GridContainer>

    </Card>
  );
}

ImageUpload.propTypes = {
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  uploadImage: PropTypes.func,
  clearError: PropTypes.func,
  vehicle: PropTypes.object,
  classes: PropTypes.object
};

function mapStateToProps(state) {
  return (
    {
      vehicle: state.data.vehicle,
      images: state.images
    });
}

export default connect(mapStateToProps,{ uploadImage, clearError })(ImageUpload);


