import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/user-tie-duotone.svg";

import styles from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";
import {connect} from "react-redux";
import {login, logout} from "../../actions/authenticationAction";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import Spinner from "../../components/Spinner";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";

const useStyles = makeStyles(styles);

const LockScreenPage = props => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [user, setUser] = React.useState();
  const [auth, setAuth] = React.useState({ username: undefined, password: undefined });

  const session_user = localStorage.getItem('user');
  if(session_user && user === undefined) {
    const lastUser = JSON.parse(session_user);
    setUser(lastUser);
    setAuth({
      ...auth,
      username: lastUser.username
    })
    console.log("LockScreenPage User ", lastUser);
    props.logout(); // log out the current user when opening this page
  }

  console.log("LockScreenPage user state", user);
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  const handleLogin = () => {
    props.login(auth.username, auth.password);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setAuth({...auth, [name]: value})
  };

  // user data lost
  if(user === undefined)
    return <Redirect to={{pathname: "/auth/login", state: {from: "/auth/lock"}}}/>;

  // relogin
  if(user !== undefined && session_user !== undefined && session_user !== null)
    return <Redirect to={{pathname: "/admin", state: {from: "/auth/lock"}}}/>;

  return (
    <div className={classes.container}>
      <form>
        <Card
          profile
          className={classes.customCardClass + " " + classes[cardAnimaton]}
        >
          <CardAvatar profile className={classes.cardAvatar}>
              <img src={avatar} alt="..." />
          </CardAvatar>
          <CardBody profile>
            <h4 className={classes.cardTitle}>{user.realName} ({user.username}) </h4>
            <p className={classes.cardTitle}>Thank you for using or service.</p>
            <CustomInput
              labelText="Enter Password"
              id="company-disabled"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: handleChange.bind(this),
                type: "password",
                autoComplete: "off",
                name: "password"
              }}
            />
            {props.auth.error &&
            <SnackbarContent
              message={"ERROR - " + props.auth.error}
              close
              color="danger"
            />}
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            {props.auth.loggingIn ?
              <Spinner/> :
              <Button color="primary" onClick={handleLogin.bind(this)}>
                Unlock
              </Button>
            }
          </CardFooter>
        </Card>
      </form>
    </div>
  );
};

LockScreenPage.propTypes = {
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = store => ({
  auth: store.auth,
});

export default connect(mapStateToProps, { logout, login })(LockScreenPage);
