import React from "react";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "../CustomInput/CustomInput";
import CardIcon from "../Card/CardIcon";
import {Money} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Spinner from "../Spinner";
import Button from "../CustomButtons/Button";

const gearboxes = ["Automatic", "Manual"];

class DriveTrain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        driveTrain: (this.props.vehicle && this.props.vehicle.driveTrain) || {
          power: 0,
          torque: 0,
          gearbox: "",
          cc: 0,
          highspeed: 0,
          acceleration: 0,
          accelerationMph: 0
        },
        modified: false,
        isEdit: props.vehicle !== undefined
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.vehicle !== this.props.vehicle || (
        prevProps.vehicle != undefined && this.props.vehicle != undefined && prevProps.vehicle.driveTrain !== this.props.vehicle.driveTrain)) {
      this.handleReset();
    }

    if(!this.state.isEdit && prevState.driveTrain !== this.state.driveTrain) {
      this.handleUpdate();
    }

    if(this.state.isEdit && prevState.driveTrain !== this.state.driveTrain && this.state.driveTrain !== this.props.vehicle.driveTrain) {
      this.setState({
        modified: true
      });
    }
  }

  handleUpdate() {
    this.props.update(this.state.driveTrain);
  }

  handleReset() {
    this.setState({
      ...this.state,
      driveTrain: this.props.vehicle.driveTrain,
      modified: false
    });
  }

  // https://upmostly.com/tutorials/react-onchange-events-with-examples
  handleChange(e) {
    this.setState({
      ...this.state,
      driveTrain: {
        ...this.state.driveTrain,
        [e.target.id]:e.target.value
      }
    });
  }

  handleValidateNumber(e) {
    this.setState({
      ...this.state,
      driveTrain: {
        ...this.state.driveTrain,
        [e.target.id]: Number(e.target.value)
      }
    });
  }

  handleToggle(e) {
    this.setState({
      ...this.state,
      driveTrain: {
        ...this.state.driveTrain,
        [e.target.id]: !this.state.driveTrain[e.target.id]
      }
    })
  }

  handleSelect(e) {
    this.setState({
      ...this.state,
      driveTrain: {
        ...this.state.driveTrain,
        [e.target.name]: e.target.value
      }
    })
  }

  render() {
    const { classes, embedded} = this.props;
    const { driveTrain, modified } = this.state;
    //console.log("DriveTrain", driveTrain);
    if(this.props.vehicle === undefined)
      return <Spinner/>;

    return (
      <Card plain={embedded}>
        <CardHeader color={"primary"} icon>
          <CardIcon color={"primary"}>
            <Money />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Drive Train</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={2}>
              <FormLabel className={classes.labelHorizontal}>
                <strong>Engine</strong>
              </FormLabel>
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                id="power"
                error={driveTrain.power < 0}
                labelText={<span>Engine Power <small>(in kW)</small></span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: driveTrain.power,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this)
                }}
              />
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                id="cc"
                error={driveTrain.cc < 0}
                labelText={<span>CC <small>(in ccm)</small></span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: driveTrain.cc,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this)
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={2}>
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                id="torque"
                error={driveTrain.torque < 0}
                labelText={<span>Torque <small>(in Nm)</small></span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: driveTrain.torque,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this)
                }}
              />
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                id="highspeed"
                error={driveTrain.highspeed < 0}
                labelText={<span>High Speed <small>(in km/h)</small></span>}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: driveTrain.highspeed,
                  onBlur: this.handleValidateNumber.bind(this),
                  onChange: this.handleChange.bind(this)
                }}
              />
            </GridItem>
          </GridContainer>

          <GridContainer>
              <GridItem xs={2}>
                <FormLabel className={classes.labelHorizontal}>
                  <strong>Acceleration</strong>
                </FormLabel>
              </GridItem>
              <GridItem xs={4}>
                <CustomInput
                  id="acceleration"
                  error={driveTrain.acceleration < 0}
                  labelText={<span>0 to 100 km/h <small>(in s)</small></span>}
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value:driveTrain.acceleration,
                    onBlur: this.handleValidateNumber.bind(this),
                    onChange: this.handleChange.bind(this)
                  }}
                />
              </GridItem>
              <GridItem xs={4}>
                <CustomInput
                  id="accelerationMph"
                  error={driveTrain.accelerationMph < 0}
                  labelText={<span>0 to 60 mph <small>(in s)</small></span>}
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value:driveTrain.accelerationMph,
                    onBlur: this.handleValidateNumber.bind(this),
                    onChange: this.handleChange.bind(this)
                  }}
                />
              </GridItem>
          </GridContainer>

            <GridContainer>
              <GridItem xs={2}></GridItem>
              <GridItem xs={8}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel htmlFor="gearbox-select" className={classes.selectLabel}>Gearbox</InputLabel>
                  <Select MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          value={driveTrain.gearbox === null ? "" : driveTrain.gearbox}
                          onChange={this.handleSelect.bind(this)}
                          inputProps={{ name: "gearbox", id: "gearbox-select" }}
                  >
                    <MenuItem disabled classes={{root: classes.selectMenuItem}}>Select a gearbox</MenuItem>
                    {gearboxes.map((v, key) =>
                      <MenuItem key={key} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value={v}>
                        {v}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

          <GridContainer>
            <GridItem xs={12}>
              {!embedded &&<Button disabled={!modified} color="success" onClick={this.handleUpdate.bind(this)}>Update drive train</Button>}
              {!embedded &&<Button disabled={!modified} onClick={this.handleReset.bind(this)}>Reset</Button>}
            </GridItem>
          </GridContainer>

        </CardBody>
      </Card>
    );
  }
}

DriveTrain.propTypes = {
  vehicle: PropTypes.object,
  classes: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  embedded: PropTypes.bool
}

export default DriveTrain;